import axios from "axios";

const getGroups = async (
  token,
  warehouseCountryId,
  flightNumber,
  status,
  barcode,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
    flightNumber: flightNumber,
    status: status,
    barcode: barcode,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcel_groups`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const registerGroup = async (groupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_registration`,
    groupData,
    config
  );

  return response.data;
};

const updateGroup = async (updatedGroupData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_change`,
    updatedGroupData,
    config
  );

  return response.data;
};

const deleteGroup = async (parcelGroupIds, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_group_delete`,
    parcelGroupIds,
    config
  );

  return response.data;
};

const groupsService = { getGroups, registerGroup, updateGroup, deleteGroup };

export default groupsService;
