import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { updateGroup } from "../../../features/groups/GroupsSlice";
import { getTransportationTypes } from "../../../features/prices/getTransportationTypes/GetTransportationTypesSlice";
import { getParcelGroupTypes } from "../../../features/groups/GroupTypesSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";
import ModalsHeadline from "../components/ModalsHeadline";
import SelectToSetLarge from "../../common/shortList/SelectToSetLarge";
import { useAppContext } from "../../../libs/AppContext";

const EditGroup = ({
  setMessage,
  setShowResponseMessage,
  setShowEditGroup,
  setSeverity,
  parcelGroupId,
}) => {
  const [formData, setFormData] = useState({
    barcode: "",
    warehouseCountryId: "",
    flightId: "",
    departmentId: "",
    parcelGroupTypeId: "",
    transportationTypeId: "",
  });
  const [errors, setErrors] = useState({
    barcode: "",
    parcelGroupTypeId: "",
    transportationTypeId: "",
  });
  // const [countryName, setCountryName] = useState("");
  const { departments } = useSelector((state) => state.parcelDepartments);
  const { flights } = useSelector((state) => state.parcelFlights);
  const { transportationTypes } = useSelector(
    (state) => state.transportationTypesForPrice
  );
  const { parcelGroupTypes } = useSelector((state) => state.parcelGroupsTypes);
  const { groups } = useSelector((state) => state.parcelGroups);
  const { setRefetch } = useAppContext();
  const country = localStorage.getItem("warehouseCountryId");

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const item = groups?.parcelGroups?.find(
      (group) => group.parcelGroupId === parcelGroupId
    );
    if (item) {
      setFormData((prev) => ({
        ...prev,
        barcode: item.barcode,
        flightId: item.flightId,
        departmentId: item.departmentId,
        parcelGroupTypeId: item.parcelGroupTypeId,
        transportationTypeId: item.transportationTypeId,
      }));
    }
  }, [groups?.parcelGroups, parcelGroupId]);

  useEffect(() => {
    dispatch(getTransportationTypes());
    dispatch(getParcelGroupTypes());
  }, [dispatch]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      warehouseCountryId: parseInt(country),
    }));
  }, [country]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleEditGroup = async (e) => {
    e.preventDefault();

    if (
      !formData.barcode ||
      !formData.parcelGroupTypeId ||
      !formData.transportationTypeId
    ) {
      setErrors({
        barcode: formData.barcode ? "" : "Barcode is required",
        parcelGroupTypeId: formData.parcelGroupTypeId
          ? ""
          : "Parcel Group Type is required",
        transportationTypeId: formData.transportationTypeId
          ? ""
          : "Transportation Type is required",
      });
      return;
    }

    const groupData = {
      parcelGroupId: parcelGroupId,
      barcode: formData.barcode,
      warehouseCountryId: formData.warehouseCountryId,
      flightId: formData.flightId,
      departmentId: formData.departmentId,
      parcelGroupTypeId: formData.parcelGroupTypeId,
      transportationTypeId: formData.transportationTypeId,
    };
    try {
      const response = await dispatch(updateGroup(groupData));
      if (response.payload.type === "success") {
        setRefetch((prev) => !prev);
        setShowEditGroup(false);
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setFormData({
          barcode: "",
          warehouseCountryId: country,
          flightId: "",
          departmentId: "",
          parcelGroupTypeId: "",
          transportationTypeId: "",
        });
      }

      if (response.error.message === "Rejected") {
        setShowEditGroup(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error);
      }
    }
  };

  return (
    <form
      onSubmit={handleEditGroup}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text="Edit parcel group"
          onClick={() => setShowEditGroup(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          {/* <div className="text-sm font-medium text-blue-900">
            {t(countryName)}
          </div> */}
          <div className="relative w-full">
            <Input
              text="List No"
              name="barcode"
              value={formData.barcode}
              onchange={handleInputChange}
              error={errors.barcode}
              errormark={errors.barcode ? "error-border" : ""}
            />
            {errors.barcode && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.barcode}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={flights?.map((cat) => ({
                value: cat.id,
                label: cat.flightNumber,
              }))}
              text="Flight No"
              name="flightId"
              value={formData.flightId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.flightId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.flightId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={departments?.map((cat) => ({
                value: cat.id,
                label: cat.address,
              }))}
              text="Branch"
              name="departmentId"
              value={formData.departmentId}
              onChange={handleInputChange}
              error={errors.departmentId}
            />
            {errors.departmentId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.departmentId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={parcelGroupTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Parcel Group Type"
              name="parcelGroupTypeId"
              value={formData.parcelGroupTypeId}
              onChange={handleInputChange}
              error={errors.parcelGroupTypeId}
            />
            {errors.parcelGroupTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.parcelGroupTypeId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={transportationTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Transportation Type"
              name="transportationTypeId"
              value={formData.transportationTypeId}
              onChange={handleInputChange}
              error={errors.transportationTypeId}
            />
            {errors.transportationTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.transportationTypeId}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.edit")}</ButtonDefault>
      </div>
    </form>
  );
};

export default EditGroup;
