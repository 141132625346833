import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ArrowRightBlue,
  EditSvgBlue,
  PhotoSvg,
  PrintBarcodeSvg,
} from "../../icons";
import { Link } from "react-router-dom";
import Spinner from "../../common/Spinner";
import Pagination from "../../common/Pagination";
import TableSearch from "../../common/TableSearch";
import ParcelPrint from "../../modals/groups/ParcelPrint";
import Message from "../../common/Message";

const ParcelsTable = ({
  currentPage,
  onNextPage,
  perPage,
  onPrevPage,
  handlePerPage,
  handlePageClick,
  handleShowPicturesList,
  handleShowEditForm,
  showEditForm,
  editParcelId,
  parcelsFormData,
  setParcelsFormData,
}) => {
  const [subCategories, setSubCategories] = useState([]);
  const [printData, setPrintData] = useState({});
  const { isLoading, categoriesWithMaster } = useSelector(
    (state) => state.groups
  );
  const { parcels } = useSelector((state) => state.groups);
  const recordsNumber = useSelector(
    (state) => state.groups?.parcels?.recordsNumber
  );
  const { t } = useTranslation();
  const printRef = useRef();

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster]);

  const getSubCategoryDescription = (categoryId) => {
    const subCategory = subCategories.find((sub) => sub.id === categoryId);
    return subCategory ? subCategory.description : t("text.not_found");
  };

  const columns = [
    { header: "Name", key: "fullName" },
    { header: "Tracking Number", key: "tdsCode" },
    { header: "Room Number", key: "roomNumber" },
    { header: "Category", key: "warehouseCategoryId" },
    { header: "Total weight", key: "weight" },
    { header: "Total Volume weight", key: "totalVolumeWeight" },
    { header: "CBM", key: "cbm" },
    { header: "E.A.I.₾, ", key: "expectedAmountGel" },
  ];

  const handlePrint = async (id) => {
    const parcel = await parcels?.find((item) => item.id === id);

    if (parcel) {
      await setPrintData(parcel);
    }

    const printContent = printRef.current.innerHTML;
    const newWindow = window.open("", "_blank");

    newWindow.document.write(`
      <html>
        <head>
          <title>Print Parcel</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              max-width: 800px;
              width: 100%;
              padding: 20px;
              box-sizing: border-box;
            }
            img {
              max-width: 100%;
              height: auto;
              margin-bottom: 10px
            }
            h2 {
              margin: 5px 0
            }
            .barcode {
              margin-top: 20px;
            }
            p {
              max-width: 270px;
              width: 100%;
              font-size: 12px;
              text-align: center;
            }
          </style>
        </head>
        <body>
          <div class="content">
            ${printContent}
          </div>
        </body>
      </html>
    `);

    newWindow.document.close();
    newWindow.focus();
    newWindow.print();
    // newWindow.onafterprint = () => newWindow.close();
    newWindow.onafterprint = () => {
      newWindow.close();
      window.focus();
    };
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="w-full flex flex-col gap-20">
          <ParcelPrint printRef={printRef} data={printData} />
          <div>
            <table className="w-full bg-white border-collapse">
              <thead className="bg-gray-100 text-sm text-blue-4">
                <tr>
                  <th className="w-12 p-4 text-left border-b border-r border-black/5 font-medium">
                    <div className="w-full flex justify-center">Barcode</div>
                  </th>
                  {columns?.map((column) => (
                    <th
                      key={column.key}
                      className={`${
                        column.key === "roomNumber" || column.key === "tdsCode"
                          ? "min-w-8"
                          : ""
                      } max-w-5 text-left border-b font-medium`}
                    >
                      <div className="relative p-4 w-full truncate border-r border-black/5">
                        {(column.key === "roomNumber" ||
                          column.key === "tdsCode") && (
                          <>
                            <span className="block truncate pr-3">
                              {column.header}
                            </span>
                            <TableSearch
                              formData={parcelsFormData}
                              setFormData={setParcelsFormData}
                              searchKey={column.key}
                              parcels="parcels"
                            />
                          </>
                        )}
                        {column.key !== "roomNumber" &&
                          column.key !== "tdsCode" && (
                            <Tooltip title={column.header}>
                              <span>{column.header}</span>
                            </Tooltip>
                          )}
                      </div>
                    </th>
                  ))}
                  <th className="max-w-2 text-left border-b font-medium w-20">
                    <div className="p-4 w-full truncate">
                      {t("text.actions")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {parcels && parcels?.parcels && parcels?.parcels.length > 0 ? (
                  <>
                    {parcels?.parcels?.map((row, index) => (
                      <tr
                        key={index}
                        className={`hover:bg-gray-50 border-black/5 border-b ${
                          showEditForm && editParcelId === row.id
                            ? "bg-blue-50"
                            : ""
                        }`}
                      >
                        <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                          <span
                            className="cursor-pointer"
                            onClick={() => handlePrint(row.id)}
                          >
                            <PrintBarcodeSvg />
                          </span>
                        </td>
                        {columns?.map((column, colIndex) => (
                          <td
                            key={colIndex}
                            className="max-w-5 text-xs text-blue-4 font-medium"
                          >
                            <div className="py-2 px-3 w-full truncate border-r border-black/5">
                              {column.key === "warehouseCategoryId" ? (
                                <Tooltip
                                  title={getSubCategoryDescription(
                                    row[column.key]
                                  )}
                                >
                                  <span>
                                    {getSubCategoryDescription(row[column.key])}
                                  </span>
                                </Tooltip>
                              ) : (
                                <Tooltip title={row[column.key]}>
                                  <span>{row[column.key]}</span>
                                </Tooltip>
                              )}
                            </div>
                          </td>
                        ))}
                        <td className="py-2 px-3 border-r border-black/5 w-20 flex justify-center">
                          <div className="flex items-center justify-center">
                            <Tooltip title="Parcel Details">
                              <Link
                                to={`/ParcelGroup/parcel/${row.id}`}
                                className="w-6 h-6 min-w-6 flex justify-center items-center"
                              >
                                <ArrowRightBlue />
                              </Link>
                            </Tooltip>

                            <Tooltip title="Photos">
                              <button
                                className={`w-6 h-6 min-w-6 flex justify-center items-center relative ${
                                  row?.parcelFiles &&
                                  row?.parcelFiles?.length > 0 &&
                                  "after:block after:h-2 after:w-2 after:rounded-full after:bg-blue-2 after:absolute after:-top-0.5 after:-right-0.5"
                                }`}
                                onClick={() => handleShowPicturesList(row.id)}
                              >
                                <PhotoSvg />
                              </button>
                            </Tooltip>

                            <Tooltip title="Edit">
                              <button
                                onClick={() => handleShowEditForm(row.id)}
                                className="w-6 h-6 min-w-6 flex justify-center items-center"
                              >
                                <EditSvgBlue />
                              </button>
                            </Tooltip>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td
                      colSpan={Object.keys(columns).length + 2}
                      className="border-none p-2"
                    >
                      <Message text={t("text.not_found")} />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {recordsNumber > 1 && (
            <Pagination
              currentPage={currentPage}
              onNextPage={onNextPage}
              onPrevPage={onPrevPage}
              handlePerPage={handlePerPage}
              perPage={perPage}
              recordsNumber={recordsNumber}
              handlePageClick={handlePageClick}
            />
          )}
        </div>
      )}
    </>
  );
};

export default ParcelsTable;
