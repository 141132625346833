import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Snackbar, Alert } from "@mui/material";
import {
  deletePotentialUser,
  getPotentialUsers,
} from "../../features/potentialUsers/PotentialUsersSlice";
import { useAppContext } from "../../libs/AppContext";
import PotentialUsersPropertesBar from "../../components/table/potentialUsers/PotentialUsersPropertesBar";
import PotentialUsersTable from "../../components/table/potentialUsers/PotentialUsersTable";
import AddButton from "../../components/common/AddButton";
import Modal from "../../components/common/Modal";
import AddPotentialUser from "../../components/modals/potentialUsers/AddPotentialUser";
import EditPotentialUser from "../../components/modals/potentialUsers/EditPotentialUser";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";

const PotentialUsers = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    pinOrInn: "",
    address: "",
    mobile: "",
    email: "",
    additionalInformation: "",
    status: "",
    page: 1,
    perPage: 10,
  });
  const {
    firstName,
    lastName,
    pinOrInn,
    address,
    mobile,
    email,
    additionalInformation,
    status,
    page,
    perPage,
  } = formData;
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const [showEditPotentialUser, setShowEditPotentialUser] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [editPotentialUserId, setEditPotentialUserId] = useState("");
  const [deletePotentialUserId, setDeletePotentialUserId] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const dispatch = useDispatch();
  const { showMainModal, setShowMainModal } = useAppContext();

  useEffect(() => {
    dispatch(
      getPotentialUsers({
        firstName,
        lastName,
        pinOrInn,
        address,
        mobile,
        email,
        additionalInformation,
        status,
        page,
        perPage,
      })
    );
  }, [
    dispatch,
    refetch,
    firstName,
    lastName,
    pinOrInn,
    address,
    mobile,
    email,
    additionalInformation,
    status,
    page,
    perPage,
  ]);

  const handleShowEditPotentialUser = (id) => {
    setEditPotentialUserId(id);
    setShowEditPotentialUser(true);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setDeletePotentialUserId(id);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeletePotentialUser = async () => {
    const potentialUserData = {
      potentialUserId: deletePotentialUserId,
    };

    const response = await dispatch(deletePotentialUser(potentialUserData));
    if (response?.payload?.type === "success") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setDeletePotentialUserId("");
      setMessage(response.payload.message);
      setSeverity("success");
      setRefetch(!refetch);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setDeletePotentialUserId("");
      setMessage(response.payload.message);
      setSeverity("error");
    }
  };

  return (
    <div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <Modal
        showmodal={showEditPotentialUser}
        setShowModal={setShowEditPotentialUser}
      >
        <EditPotentialUser
          setMessage={setMessage}
          setSeverity={setSeverity}
          setShowResponseMessage={setShowResponseMessage}
          setRefetch={setRefetch}
          refetch={refetch}
          potentialUserId={editPotentialUserId}
          setShowEditPotentialUser={setShowEditPotentialUser}
        />
      </Modal>
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddPotentialUser
          setMessage={setMessage}
          setSeverity={setSeverity}
          setShowResponseMessage={setShowResponseMessage}
          setRefetch={setRefetch}
          refetch={refetch}
        />
      </Modal>
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeletePotentialUser}
        />
      )}
      <PotentialUsersPropertesBar />
      <PotentialUsersTable
        handleShowEditPotentialUser={handleShowEditPotentialUser}
        handleDeleteConfirmation={handleDeleteConfirmation}
        formData={formData}
        setFormData={setFormData}
      />
      <AddButton />
    </div>
  );
};

export default PotentialUsers;
