const Input = (props) => {
  const {
    isicon,
    icon,
    handleshowpassword,
    name,
    value,
    onchange,
    errormark,
    type,
    text,
  } = props;

  return (
    <div
      className={`relative w-full rounded-md overflow-hidden bg-white ${
        errormark === "error-border" ? "border-red-500" : "border-gray-2"
      } border`}
    >
      {isicon === "isicon" && (
        <span
          onClick={handleshowpassword}
          className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer z-10 flex justify-center items-center h-5 w-5 md:h-6 md:w-6"
        >
          {icon}
        </span>
      )}
      <input
        className="input w-full transition-all duration-500 ease-in-out pt-6 pb-1.5 px-4 text-gray-1 text-sm font-normal placeholder-transparent focus:outline-none md:pt-7 md:pb-2"
        type={type}
        placeholder=" "
        name={name}
        value={value}
        onChange={onchange}
        autoComplete="off"
      />
      <span className="placeholder-span absolute top-4 left-4 text-gray-1 text-sm font-normal pointer-events-none">
        {text}
      </span>
    </div>
  );
};

export default Input;
