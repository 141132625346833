import axios from "axios";

const getParcels = async (token, reportType) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    reportType: reportType,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/report/get_report`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const deleteParcels = async (parcelsIds, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/delete_parcels`,
    parcelsIds,
    config
  );

  return response.data;
};

const changeGroupForParcel = async (parcelData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel_group/parcel_change_group`,
    parcelData,
    config
  );

  return response.data;
};

const parcelsService = {
  getParcels,
  deleteParcels,
  changeGroupForParcel,
};

export default parcelsService;
