import { RxHamburgerMenu } from "react-icons/rx";
import { useAppContext } from "../../libs/AppContext";

const BurgerMenu = () => {
  const { handleMobileMenu } = useAppContext();
  return (
    <RxHamburgerMenu
      onClick={handleMobileMenu}
      className="block z-20 text-2xl top-2 left-4 cursor-pointer"
    />
  );
};

export default BurgerMenu;
