import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { getParcelGroups } from "../../../features/notifications/parcelGroups/ParcelGroupsSlice";
import ModalsHeadline from "../components/ModalsHeadline";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";
import { useDispatch, useSelector } from "react-redux";
import { changeGroupForParcel } from "../../../features/parcels/ParcelsSlice";
import { useAppContext } from "../../../libs/AppContext";

const ChangeGroupForParcel = ({
  handleShowModal,
  parcelIds,
  setParcelIds,
  setSelectedRows,
  setShowResponseMessage,
  setSeverity,
  setMessage,
  setShowGroupChangeModal,
}) => {
  const [formData, setFormData] = useState({
    barcode: "",
    id: "",
  });
  const [options, setOptions] = useState(false);
  const { notificationParcelGroups } = useSelector(
    (state) => state.notificationsParcelGroups
  );
  const debounceTimeout = useRef();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parcelsRefetch, setParcelsRefetch } = useAppContext();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (value.length > 2) {
      setOptions(true);
      if (debounceTimeout.current) {
        clearTimeout(debounceTimeout.current);
      }

      debounceTimeout.current = setTimeout(() => {
        dispatch(getParcelGroups(value));
      }, 500);
    } else {
      setOptions(false);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChoiseGroup = (id) => {
    let group = notificationParcelGroups?.find((group) => group.id === id);
    setFormData((prev) => ({
      ...prev,
      barcode: group.barcode,
      id: group.id,
    }));
    setOptions(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSend = {
      parcelGroupId: formData.id,
      parcelIds: parcelIds,
    };

    try {
      const response = await dispatch(changeGroupForParcel(dataToSend));

      if (response.payload.type === "success") {
        setParcelsRefetch(!parcelsRefetch);
        setShowGroupChangeModal(false);
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setParcelIds([]);
        setSelectedRows([]);
        setFormData({
          barcode: "",
          id: "",
        });
      }

      if (response.error.message === "Rejected") {
        setShowGroupChangeModal(false);
        setShowResponseMessage(true);
        setMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {}
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline
          text={t("text.change_parcel_group")}
          onClick={handleShowModal}
        />
        <div className="p-6 w-full">
          <div className="relative">
            <Input
              text={t("text.barcode")}
              name="barcode"
              onchange={handleInputChange}
              value={formData.barcode}
            />
            {options && (
              <div className="absolute z-20 w-full max-h-lg h-lg overflow-auto rounded-md bg-white border border-gray-200 mt-2">
                {notificationParcelGroups ? (
                  notificationParcelGroups?.map((group) => (
                    <div
                      key={group.id}
                      onClick={() => handleChoiseGroup(group.id)}
                      className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                    >
                      {group.barcode}
                    </div>
                  ))
                ) : (
                  <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                    {t("text.not_found")}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("button.save")}</ButtonDefault>
      </div>
    </form>
  );
};

export default ChangeGroupForParcel;
