import { useState, useEffect, useRef } from "react";
import InputSmall from "../../common/InputSmall";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { searchUser } from "../../../features/groups/UsersSlice";
import {
  getCategoriesWithMaster,
  getNewParcelInfo,
  getParcelServices,
} from "../../../features/groups/GroupsSlice";
import { generateTrackingCode } from "../../../features/groups/trackingCodeSlice";
import {
  CloseModalSvg,
  LengthSvg,
  QuantitySvg,
  WeightSvg,
  WidthSvg,
} from "../../icons";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import { IoWarning } from "react-icons/io5";
import Checkbox from "../../common/Checkbox";
import SelectSmall from "../../common/SelectSmall";
import Input from "../../common/Input";
import upload from "../../../assets/upload-picture.png";
import clear from "../../../assets/clear.png";

const ParcelForm = ({
  handleSubmit,
  setFormData,
  formData,
  setShouldPrint,
  shouldPrint,
  errors,
  setErrors,
  setSelectedMainCategory,
  setSelectedSubCategory,
  selectedMainCategory,
  selectedSubCategory,
  setShowUploadModal,
  subCategories,
  setSubCategories,
}) => {
  const [isDivVisible, setIsDivVisible] = useState(true);
  const [showRoomNumbersOptions, setShowRoomNumbersOptions] = useState(false);
  const parcelCodeInputRef = useRef(null);
  const weightInputRef = useRef(null);
  const roomNumberInputRef = useRef(null);
  const debounceTimeout = useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newParcelInfo, categoriesWithMaster, parcelServices } = useSelector(
    (state) => state.groups
  );
  const { users } = useSelector((state) => state.roomUsers);
  const { trackingCode } = useSelector((state) => state.trackingCode);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
    dispatch(getParcelServices());
  }, [dispatch]);

  useEffect(() => {
    parcelCodeInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (newParcelInfo && newParcelInfo.length > 0) {
      setFormData((prev) => ({
        ...prev,
        roomNumber: newParcelInfo[0]?.roomNumber,
      }));
    }
  }, [newParcelInfo, setFormData]);

  useEffect(() => {
    if (trackingCode?.message) {
      setFormData((prev) => ({
        ...prev,
        tds_code: trackingCode.message,
      }));
    }

    setErrors((prev) => ({
      ...prev,
      tds_code: "",
    }));

    // roomNumberInputRef.current.focus();
  }, [trackingCode, setFormData, setErrors]);

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster, setSubCategories]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      warehouseCategoryId: selectedSubCategory,
    }));
  }, [selectedSubCategory, setFormData]);

  const calculateTotals = () => {
    const totalQuantity = formData.parcelDetails.reduce(
      (total, detail) => total + (parseFloat(detail.quantity) || 0),
      0
    );

    const totalWeight = formData.parcelDetails.reduce((total, detail) => {
      const weight = parseFloat(detail.weight) || 0;
      const length = parseFloat(detail.length) || 0;
      const width = parseFloat(detail.width) || 0;
      const height = parseFloat(detail.height) || 0;

      const volumetricWeight = (length * width * height) / 6000;

      const effectiveWeight = Math.max(weight, volumetricWeight);

      return total + effectiveWeight;
    }, 0);

    return {
      totalQuantity: parseFloat(totalQuantity.toFixed(2)),
      totalWeight: parseFloat(totalWeight.toFixed(2)),
    };
  };

  const { totalQuantity, totalWeight } = calculateTotals();

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const englishRegex = /^[a-zA-Z0-9\s\p{P}\p{S}]*$/u;

    if (!englishRegex.test(value) && name !== "warehouseComment") {
      setErrors((prev) => ({
        ...prev,
        [name]: "Only English characters are allowed",
      }));
      return;
    } else {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    if (name === "roomNumber" || name === "tds_code") {
      setErrors((prev) => ({
        ...prev,
        [name]: "",
      }));
    }

    const numericFields = ["quantity", "weight", "length", "width", "height"];
    const isNumericField = numericFields.includes(name);

    if (isNumericField && !/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (
      name === "tds_code" ||
      name === "roomNumber" ||
      name === "warehouseComment"
    ) {
      setFormData({
        ...formData,
        [name]: value,
      });

      if (name === "roomNumber" && value.length > 2) {
        setShowRoomNumbersOptions(true);
        if (debounceTimeout.current) {
          clearTimeout(debounceTimeout.current);
        }

        debounceTimeout.current = setTimeout(() => {
          dispatch(searchUser(value));
        }, 500);
      } else {
        setShowRoomNumbersOptions(false);
      }

      return;
    }

    const updatedParcelDetails = [...formData.parcelDetails];
    updatedParcelDetails[index] = {
      ...updatedParcelDetails[index],
      [name]: value,
    };

    const length = parseFloat(updatedParcelDetails[index].length || 0);
    const width = parseFloat(updatedParcelDetails[index].width || 0);
    const height = parseFloat(updatedParcelDetails[index].height || 0);
    const VOLUMETRIC_FACTOR = 6000;

    if (length && width && height) {
      updatedParcelDetails[index].volumetricWeight =
        (length * width * height) / VOLUMETRIC_FACTOR;
    } else {
      updatedParcelDetails[index].volumetricWeight = undefined;
    }

    const isRowEmpty = Object.values(updatedParcelDetails[index]).every(
      (field) => field === "" || field === undefined
    );

    if (isRowEmpty && formData.parcelDetails.length > 1) {
      updatedParcelDetails.splice(index, 1);
    }

    setFormData({
      ...formData,
      parcelDetails: updatedParcelDetails,
    });

    const lastBlock = updatedParcelDetails[updatedParcelDetails.length - 1];
    if (
      name !== "weight" &&
      (lastBlock.quantity ||
        lastBlock.length ||
        lastBlock.width ||
        lastBlock.height) &&
      formData.parcelDetails.length === index + 1
    ) {
      setFormData({
        ...formData,
        parcelDetails: [
          ...updatedParcelDetails,
          {
            quantity: "",
            weight: "",
            length: "",
            width: "",
            height: "",
          },
        ],
      });
    }

    const newErrors = { ...errors };
    if (newErrors[`parcelDetails_${index}`]?.[name]) {
      delete newErrors[`parcelDetails_${index}`][name];

      if (Object.keys(newErrors[`parcelDetails_${index}`]).length === 0) {
        delete newErrors[`parcelDetails_${index}`];
      }
      setErrors(newErrors);
    }
  };

  const handleKeyDown = async (e) => {
    const { name } = e.target;

    if (e.key === "Enter") {
      e.preventDefault();

      if (name === "tds_code" && formData.tds_code !== "") {
        const response = await dispatch(getNewParcelInfo(formData.tds_code));
        if (
          response.payload &&
          response.payload.length > 0 &&
          response.payload[0].roomNumber
        ) {
          weightInputRef.current.focus();
        } else {
          roomNumberInputRef.current.focus();
        }
      } else {
        const formElements = Array.from(e.target.form.elements);
        const currentElementIndex = formElements.indexOf(e.target);
        const nextElement = formElements[currentElementIndex + 1];

        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  };

  const handleGenerateTrackingCode = async () => {
    await dispatch(generateTrackingCode());
    roomNumberInputRef.current.focus();
  };

  const handleChoiseUser = (id) => {
    let user = users?.find((user) => user.id === id);
    setFormData((prev) => ({
      ...prev,
      roomNumber: user.roomNumber,
    }));
    setShowRoomNumbersOptions(false);
    weightInputRef.current.focus();
  };

  const handleMainCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;

    setSelectedMainCategory(selectedCategoryId);
    setSelectedSubCategory("");

    const parentCategory = categoriesWithMaster.find(
      (category) => category.id === parseInt(selectedCategoryId)
    );
    setSubCategories(parentCategory?.children || []);
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;

    setSelectedSubCategory(selectedSubCategoryId);
    const parentCategory = categoriesWithMaster.find((category) =>
      category.children.some(
        (sub) => sub.id === parseInt(selectedSubCategoryId)
      )
    );
    if (parentCategory) {
      setSelectedMainCategory(parentCategory.id);
    }
  };

  const handleClearForm = () => {
    setFormData((prev) => ({
      ...prev,
      tds_code: "",
      roomNumber: "",
      parcelDetails: [
        {
          quantity: "",
          weight: "",
          length: "",
          width: "",
          height: "",
        },
      ],
      warehouseComment: "",
      warehouseCategoryId: "",
      "file[]": [],
    }));
    setSelectedSubCategory("");
    setSelectedMainCategory("");
    parcelCodeInputRef.current.focus();
  };

  const handleCloseDiv = () => {
    setIsDivVisible(false);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="grid gap-4 xl:grid-cols-[auto_319px] px-6 py-10 transition-all duration-500"
    >
      <div className="flex flex-col items-start gap-6">
        <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
          <div className="relative">
            <div className="relative">
              <span
                className="cursor-pointer absolute z-20 top-1/2 transform -translate-y-1/2 right-2 text-blue-2 bg-blue-2/30 text-xs py-1 px-2.5 rounded-lg transition-all duration-300 hover:bg-blue-2/40"
                onClick={handleGenerateTrackingCode}
              >
                Generate
              </span>
              <InputSmall
                inputRef={parcelCodeInputRef}
                onKeyDown={(e) => handleKeyDown(e, 0)}
                value={formData.tds_code}
                name="tds_code"
                onchange={handleInputChange}
                text="გზავნილის კოდი"
                errormark={errors.tds_code ? "error-border" : ""}
              />
            </div>
            {errors.tds_code && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.tds_code}
              </p>
            )}
          </div>

          <div className="relative">
            <div className="relative">
              <InputSmall
                inputRef={roomNumberInputRef}
                text="ოთახის ნომერი"
                name="roomNumber"
                onchange={handleInputChange}
                value={formData.roomNumber}
                onKeyDown={(e) => handleKeyDown(e, 0)}
                errormark={errors.roomNumber ? "error-border" : ""}
              />
              {showRoomNumbersOptions && (
                <div className="absolute z-20 w-full max-h-lg overflow-auto rounded-xl bg-white border border-gray-200">
                  {users ? (
                    users.map((user) => (
                      <div
                        key={user.id}
                        onClick={() => handleChoiseUser(user.id)}
                        className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                      >
                        {user.label}
                      </div>
                    ))
                  ) : (
                    <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                      {t("text.not_found")}
                    </div>
                  )}
                </div>
              )}
            </div>
            {errors.roomNumber && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.roomNumber}
              </p>
            )}
          </div>
        </div>

        {formData.parcelDetails.map((detail, index) => (
          <div
            key={index}
            className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-6"
          >
            <div className="relative">
              <InputSmall
                inputRef={index === 0 ? weightInputRef : null}
                text="წონა"
                name="weight"
                onchange={(e) => handleInputChange(e, index)}
                value={detail.weight}
                onKeyDown={(e) => handleKeyDown(e, index)}
                isicon="isicon"
                icon={<WeightSvg />}
                errormark={
                  errors[`parcelDetails_${index}`]?.weight ? "error-border" : ""
                }
              />
              {errors[`parcelDetails_${index}`]?.weight && (
                <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4 whitespace-nowrap truncate w-2/3">
                  {errors[`parcelDetails_${index}`]?.weight}
                </p>
              )}
            </div>

            <div className="relative">
              <InputSmall
                text="რაოდენობა"
                name="quantity"
                onchange={(e) => handleInputChange(e, index)}
                value={detail.quantity}
                onKeyDown={(e) => handleKeyDown(e, index)}
                isicon="isicon"
                icon={<QuantitySvg />}
                errormark={
                  errors[`parcelDetails_${index}`]?.quantity
                    ? "error-border"
                    : ""
                }
              />
              {errors[`parcelDetails_${index}`]?.quantity && (
                <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4 whitespace-nowrap truncate w-2/3">
                  {errors[`parcelDetails_${index}`]?.quantity}
                </p>
              )}
            </div>

            <InputSmall
              text="სიგრძე (სმ)"
              name="length"
              onchange={(e) => handleInputChange(e, index)}
              value={detail.length}
              onKeyDown={(e) => handleKeyDown(e, index)}
              isicon="isicon"
              icon={<LengthSvg />}
            />

            <InputSmall
              text="სიგანე (სმ)"
              name="width"
              onchange={(e) => handleInputChange(e, index)}
              value={detail.width}
              onKeyDown={(e) => handleKeyDown(e, index)}
              isicon="isicon"
              icon={<WidthSvg />}
            />

            <InputSmall
              text="სიმაღლე (სმ)"
              name="height"
              onchange={(e) => handleInputChange(e, index)}
              value={detail.height}
              onKeyDown={(e) => handleKeyDown(e, index)}
              isicon="isicon"
              icon={<LengthSvg />}
            />

            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              {detail.volumetricWeight
                ? detail.volumetricWeight.toFixed(2)
                : "N/A"}
            </div>
          </div>
        ))}

        <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
          <SelectSmall
            options={categoriesWithMaster.map((cat) => ({
              value: cat.id,
              label: cat.description,
            }))}
            text="Goods type"
            name="warehouseCategoryId"
            value={selectedMainCategory}
            onchange={handleMainCategoryChange}
          />
          <SelectSmall
            options={subCategories.map((sub) => ({
              value: sub.id,
              label: sub.description,
            }))}
            text="Sub type"
            name="warehouseSubCategory"
            value={selectedSubCategory}
            onchange={handleSubCategoryChange}
          />
        </div>
        <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
          <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
            ჯამური რაოდენობა {totalQuantity}
          </div>
          <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
            ჯამური წონა {totalWeight}
          </div>
        </div>
        {newParcelInfo[0]?.parcelServices ? (
          <div className="relative w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
            {newParcelInfo[0]?.parcelServices.map((service, index) => (
              <div
                key={index}
                className="flex justify-center items-center gap-2"
              >
                <Checkbox
                  checked={service.serviceStatusFlag === "Y" ? true : false}
                />
                <span className="text-xs text-gray-400">
                  {service.serviceDesc}
                </span>
              </div>
            ))}
            {newParcelInfo[0]?.parcelServices.some(
              (service) => service.serviceStatusFlag === "Y"
            ) &&
              isDivVisible && (
                <div className="absolute right-2 bottom-2 max-w-64 w-full bg-gray-200 shadow-md py-5 px-6 rounded-xl text-sm text-blue-1 border-gray-200 flex flex-col gap-2 items-center">
                  <div
                    className="absolute top-2 right-2 cursor-pointer w-5 h-5 flex justify-center items-center opacity-50 hover:opacity-100 transition-all duration-300"
                    onClick={handleCloseDiv}
                  >
                    <CloseModalSvg />
                  </div>
                  <IoWarning className="text-yellow-400 text-4xl" />
                  <p className="text-xs text-gray-6 text-center">
                    მომხმარებელი ითხოვს{" "}
                    {newParcelInfo[0]?.parcelServices
                      .filter((service) => service.serviceStatusFlag === "Y")
                      .map((service) => service.serviceDesc)
                      .join(", ")}
                  </p>
                </div>
              )}
          </div>
        ) : (
          <div className="relative w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
            {parcelServices?.map((service) => (
              <div
                key={service.id}
                className="flex justify-center items-center gap-2"
              >
                <Checkbox checked={false} />
                <span className="text-xs text-gray-400">
                  {service.serviceDesc}
                </span>
              </div>
            ))}
          </div>
        )}

        <div className="w-full">
          <Input
            text="კომენტარი"
            name="warehouseComment"
            onchange={handleInputChange}
            value={formData.warehouseComment}
          />
        </div>
      </div>
      <div className="flex flex-col justify-between gap-4 xl:px-9">
        <div className="flex flex-col items-center w-full gap-5">
          <div
            onClick={handleClearForm}
            className="w-full p-1.5 flex justify-center items-center gap-4 rounded-xl border border-red-300 cursor-pointer transition-all duration-300 hover:border-red-500"
          >
            <img src={clear} alt="clear" />
            <span className=" text-base font-medium text-blue-1">Clear</span>
          </div>
          <div
            onClick={() => setShowUploadModal(true)}
            className="w-full p-1.5 flex justify-center items-center gap-4 rounded-xl border border-dashed border-blue-300 cursor-pointer transition-all duration-300 hover:border-solid"
          >
            <img src={upload} alt="" />
            <span className=" text-base font-medium text-blue-1">
              Upload Image
            </span>
          </div>
          <div className="flex justify-start items-center gap-2 w-full text-base text-purple-3">
            <Checkbox
              checked={shouldPrint}
              onChange={() => setShouldPrint(!shouldPrint)}
            />
            Print
          </div>
        </div>
        <ButtonPrimary type="submit">შენახვა</ButtonPrimary>
      </div>
    </form>
  );
};

export default ParcelForm;
