import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parcelsService from "./ParcelsService";

const initialState = {
  parcels: [],
  statusCode: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getParcels = createAsyncThunk(
  "report/parcels",
  async ({ reportType }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelsService.getParcels(token, reportType);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
        statusCode: error.response?.status,
      });
    }
  }
);

export const deleteParcels = createAsyncThunk(
  "parcel_group/delete_parcels`",
  async (parcelsIds, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelsService.deleteParcels(parcelsIds, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        //
      });
    }
  }
);

export const changeGroupForParcel = createAsyncThunk(
  "parcel_group/parcel_change_group`",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelsService.changeGroupForParcel(parcelData, token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
        //
      });
    }
  }
);

export const parcelsSlice = createSlice({
  name: "parcels",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parcels = action.payload;
        state.statusCode = 200;
      })
      .addCase(getParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.statusCode = action.payload.statusCode;
      })
      .addCase(deleteParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const parcelsIdsToDelete = action.payload?.parcelsIds || [];

        if (state.parcels && state.parcels?.parcels) {
          state.parcels = {
            ...state.parcels,
            parcels: state.parcels?.parcels?.map((parcel) => {
              return {
                ...parcel,
                parcels: (parcel?.parcels?.parcels || []).filter(
                  (subParcel) => !parcelsIdsToDelete.includes(subParcel.id)
                ),
              };
            }),
          };
        }
      })
      .addCase(deleteParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = parcelsSlice.actions;
export default parcelsSlice.reducer;
