import { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { BackSvg } from "../../components/icons";
import { useDispatch } from "react-redux";
import { Alert, Snackbar } from "@mui/material";
import { registerIndividualParcel } from "../../features/groups/parcels/ParcelsSlice";
import { reset as resetCalcs } from "../../features/groups/CalculationsSlice";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import IndividualParcelForm from "../../components/forms/parcelGroups/IndividualParcelForm";
import Modal from "../../components/common/Modal";
import UploadParcelPictures from "../../components/modals/groupsNew/UploadParcelPictures";
import IndividualParcelPrint from "../../components/modals/groups/IndividualParcelPrint";

const AddIndividualParcel = () => {
  const [formData, setFormData] = useState({
    parcelGroupId: "",
    tdsCode: "",
    parcelDetails: [
      {
        quantity: "",
        weight: "",
        length: "",
        width: "",
        height: "",
      },
    ],
    warehouseComment: "",
    warehouseCategoryId: "",
    "file[]": [],
    senderPotentialUserId: "",
    receiverUserId: "",
    senderUserId: "",
    address: null,
    departmentId: "",
    price: "",
    cityId: "",
    addressComment: "",
    sender: {
      firstName: "",
      lastName: "",
      pinOrInn: "",
      address: "",
      mobile: "",
      email: "",
      additionalInformation: "",
    },
    receiver: {
      firstName: "",
      lastName: "",
      pinOrInn: "",
      address: "",
      mobile: "",
      email: "",
      additionalInformation: "",
    },
  });

  const [shouldPrint, setShouldPrint] = useState(false);
  const [printData, setPrintData] = useState({});
  const [errors, setErrors] = useState({});
  const [selectedMainCategory, setSelectedMainCategory] = useState("");
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const printRef = useRef();

  const [severity, setSeverity] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [message, setMessage] = useState("");

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { parcelGroupId } = useParams();

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      parcelGroupId: parcelGroupId,
    }));
  }, [parcelGroupId]);

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formattedParcelDetails = formData.parcelDetails
      .map((detail) => ({
        quantity: parseFloat(detail.quantity) || 0,
        weight: parseFloat(detail.weight) || 0,
        length: parseFloat(detail.length) || 0,
        width: parseFloat(detail.width) || 0,
        height: parseFloat(detail.height) || 0,
      }))
      .filter(
        (detail) =>
          detail.quantity !== 0 ||
          detail.weight !== 0 ||
          detail.length !== 0 ||
          detail.width !== 0 ||
          detail.height !== 0
      );

    const formDataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      if (key === "parcelDetails") {
        formDataToSend.append(key, JSON.stringify(formattedParcelDetails));
      } else if (key === "file[]") {
        value.forEach((file) => {
          formDataToSend.append("file[]", file);
        });
      } else if (key === "sender" || key === "receiver") {
        formDataToSend.append(key, JSON.stringify(value));
      } else if (key === "address") {
        if (value !== "") {
          formDataToSend.append(key, JSON.stringify(value));
        } else {
          formDataToSend.append(key, value);
        }
      } else if (key === "tdsCode") {
        formDataToSend.append("tdsCode", value);
      } else if (key === "parcelId") {
        formDataToSend.append("parcelGroupId", parcelGroupId);
      } else {
        formDataToSend.append(key, value);
      }
    });

    try {
      const response = await dispatch(registerIndividualParcel(formDataToSend));
      if (response.payload && response.payload.type === "success") {
        setShowResponseMessage(true);
        setSeverity("success");
        setMessage(response.payload.message);
        setFormData((prev) => ({
          ...prev,
          tdsCode: "",
          parcelDetails: [
            {
              quantity: "",
              weight: "",
              length: "",
              width: "",
              height: "",
            },
          ],
          warehouseComment: "",
          warehouseCategoryId: "",
          "file[]": [],
          senderPotentialUserId: "",
          receiverUserId: "",
          senderUserId: "",
          address: "",
          departmentId: "",
          price: "",
          cityId: "",
          sender: {
            firstName: "",
            lastName: "",
            pinOrInn: "",
            address: "",
            mobile: "",
            email: "",
            additionalInformation: "",
          },
          receiver: {
            firstName: "",
            lastName: "",
            pinOrInn: "",
            address: "",
            mobile: "",
            email: "",
            additionalInformation: "",
          },
        }));
        dispatch(resetCalcs());
        setShouldPrint(false);
        setSelectedSubCategory("");
        setSelectedMainCategory("");
        setPrintData(response.payload.data);
        if (shouldPrint && printData) {
          setTimeout(() => handlePrint(), 0);
        }
      }

      if (response.error && response.error.message === "Rejected") {
        setSeverity("error");
        setShowResponseMessage(true);
        setMessage(response.payload.message);
      }
    } catch (error) {}
  };

  const handlePrint = () => {
    if (formData.tdsCode !== "") {
      const printContent = printRef.current.innerHTML;
      const newWindow = window.open("", "_blank");

      newWindow.document.write(`
        <html>
          <head>
            <title>Print Parcel</title>
            <style>
            body {
              font-family: Arial, sans-serif;
              margin: 0;
              padding: 0;
              height: 100vh;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
            }
            .content {
              display: flex;
              flex-direction: column;
              align-items: center;
              text-align: center;
              max-width: 800px;
              width: 100%;
              padding: 20px;
              box-sizing: border-box;
            }
            img {
              display: block;
              max-width: 200px;
              height: auto;
              margin-bottom: 10px
            }
            h2 {
              margin: 5px 0
            }
            h3 {
              font-size: 14px
            }
            .barcode {
              margin-top: 20px;
            }
            p {
              max-width: 270px;
              width: 100%;
              font-size: 12px;
              text-align: center;
            }
            .grid {
              display: grid;
              grid-template-columns: repeat(2, 1fr);
              max-width: 270px;
              width: 100%;
            }
            .first {
              border-right: solid 1px #000;
              padding:5px;
              text-align: start;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
            .second{
              padding:5px;
              text-align: end;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          </style>
          </head>
          <body>
            ${printContent}
          </body>
        </html>
      `);

      newWindow.document.close();
      newWindow.focus();
      newWindow.print();
      newWindow.onafterprint = () => {
        newWindow.close();
        window.focus();
      };
    }
  };

  return (
    <ParcelGroupsLayout>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      <IndividualParcelPrint
        printRef={printRef}
        data={printData}
        formData={formData}
      />
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to={`/ParcelGroupNew/${parcelGroupId}/Parcels`}
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11 cursor-pointer"
          >
            <BackSvg />
          </Link>
          <div className="text-sm font-semibold text-gray-800 md:text-lg lg:text-xl">
            ინდივიდუალური ამანათის {t("text.add")}
          </div>
        </div>
      </div>
      <Modal showmodal={showUploadModal} setShowModal={setShowUploadModal}>
        <UploadParcelPictures
          formData={formData}
          setFormData={setFormData}
          setShowUploadModal={setShowUploadModal}
        />
      </Modal>
      <IndividualParcelForm
        handleSubmit={handleSubmit}
        setFormData={setFormData}
        formData={formData}
        setShouldPrint={setShouldPrint}
        shouldPrint={shouldPrint}
        errors={errors}
        setErrors={setErrors}
        setSelectedMainCategory={setSelectedMainCategory}
        setSelectedSubCategory={setSelectedSubCategory}
        selectedMainCategory={selectedMainCategory}
        selectedSubCategory={selectedSubCategory}
        setShowUploadModal={setShowUploadModal}
        subCategories={subCategories}
        setSubCategories={setSubCategories}
      />
    </ParcelGroupsLayout>
  );
};

export default AddIndividualParcel;
