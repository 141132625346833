import { useEffect, useState } from "react";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import Spinner from "../common/Spinner";

const libraries = ["places"];

const AddressSearchSmall = ({ onUpdate, text, value }) => {
  const [autocomplete, setAutocomplete] = useState(null);
  const [inputValue, setInputValue] = useState(value?.formatted_address || "");
  const userLanguage = navigator.language || "ka";

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: "AIzaSyDjnyMgqkEvbIBGAT1Qw_jNtOFOJJnssMI",
    libraries,
    language: userLanguage,
  });

  useEffect(() => {
    if (!value?.formatted_address) {
      setInputValue("");
    }
  }, [value]);

  if (!isLoaded) return <Spinner />;

  const onLoad = (autocompleteInstance) =>
    setAutocomplete(autocompleteInstance);

  const onPlaceChanged = () => {
    if (autocomplete) {
      const place = autocomplete.getPlace();
      if (place.geometry) {
        const formattedAddress = place.formatted_address || "";
        setInputValue(formattedAddress);
        const address = place;
        onUpdate(address);
      } else {
        console.error("No details available for input: ", place);
      }
    }
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  return (
    <div className="w-full">
      <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
        <input
          type="text"
          placeholder={text}
          value={inputValue}
          onChange={handleInputChange}
          className="w-full relative cursor-pointer mt-1 rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-xs text-gray-1 placeholder:text-gray-1 outline-none"
        />
      </Autocomplete>
    </div>
  );
};

export default AddressSearchSmall;
