import { ReactBarcode as Barcode } from "react-jsbarcode";
import logo from "../../../assets/space-cargo-logo-2.svg";

const IndividualParcelPrint = ({
  printRef,
  data,
  formData,
  length,
  index,
  details,
}) => {
  const storedId = localStorage.getItem("warehouseCountryId");
  const barcodeValue = formData?.tds_code || data?.tdsCode || "DEFAULT";

  return (
    <div className="hidden w-full h-full" id={`print-content-${index}`}>
      <div ref={printRef} className="content">
        <img className="max-w-xxl w-full" src={logo} alt="Space Cargo" />
        <div className="grid">
          <div className="first">
            <h3>
              {data?.participants?.receiver?.firstName}{" "}
              {data?.participants?.receiver?.lastName}
            </h3>
            <span>{data?.participants?.receiver?.pinOrInn}</span>
          </div>
          <div className="second">
            <h3>
              {data?.participants?.sender?.firstName}{" "}
              {data?.participants?.sender?.lastName}
            </h3>
            <span>{data?.participants?.sender?.pinOrInn}</span>
          </div>
        </div>
        {storedId === "210" && data.warehouseComment && (
          <p>{data.warehouseComment}</p>
        )}
        {details === "details" && (
          <span>
            {length} - {index + 1}
          </span>
        )}
        <div
          style={{
            maxWidth: "1000px",
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Barcode
            value={barcodeValue}
            format="CODE128"
            displayValue={true}
            lineColor="#000"
            width={2}
            height={40}
            textAlign="center"
            margin={10}
          />
        </div>
      </div>
    </div>
  );
};

export default IndividualParcelPrint;
