import { useAppContext } from "../../../libs/AppContext";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  DeleteSvg,
  DollarSvg,
  EmailSvg,
  SmsSvg,
  RoleChangeSvg,
  BackSvg,
} from "../../icons";
import Tooltip from "@mui/material/Tooltip";
import ListBoxSmall from "../../common/shortList/ListBoxSmall";

const GroupsPropertisBar = ({
  parcelgroupsdata,
  setparcelgroupsdata,
  groupsIds,
  handleShowChangeRate,
  handleDeleteConfirmation,
  handleShowSendSms,
  handleShowSendEmail,
  handleShowChangeStatus,
  allStatusIsSame,
  isStatusSent,
}) => {
  const { t } = useTranslation();
  const { countries } = useSelector((state) => state.parcelCountries);
  const { isPermission } = useAppContext();

  useEffect(() => {
    const savedCountry = localStorage.getItem("warehouseCountryId");
    if (savedCountry) {
      setparcelgroupsdata((prevData) => ({
        ...prevData,
        warehouseCountryId: savedCountry,
      }));
    }
  }, [setparcelgroupsdata]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "warehouseCountryId") {
      localStorage.setItem("warehouseCountryId", value);
    }

    setparcelgroupsdata({
      ...parcelgroupsdata,
      [name]: value,
    });
  };

  return (
    <div className="w-full border-b border-gray-300 bg-gray-100 flex flex-col md:justify-between md:items-center">
      <div className="p-4 flex items-center justify-between w-full">
        <div className="flex items-center justify-start gap-3 w-full">
          <Link
            to="/MainPage"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          <div className="text-lg font-semibold text-gray-800 md:text-xl">
            {t("text._navigation.parcelGroups")}
          </div>
        </div>
        <div className="w-full max-w-60 flex items-center justify-end bg-white rounded-lg">
          <ListBoxSmall
            options={countries?.map((cat) => ({
              value: cat.countryId,
              label: t(cat.countryDictionaryKey),
            }))}
            text={t("text.calculator_select_country")}
            name="warehouseCountryId"
            value={parcelgroupsdata.warehouseCountryId}
            onChange={handleInputChange}
          />
        </div>
      </div>
      {parcelgroupsdata.warehouseCountryId && groupsIds.length > 0 && (
        <div className="p-4 w-full flex items-center justify-start gap-2  border-t border-gray-300">
          {isPermission?.parcelGroupDelete?.action === "parcelGroupDelete" && (
            <Tooltip title="Delete">
              <button
                className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
                onClick={handleDeleteConfirmation}
              >
                <DeleteSvg />
              </button>
            </Tooltip>
          )}
          {isPermission?.sendSms?.action === "sendSms" && (
            <Tooltip title="Send a Message">
              <button
                className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
                onClick={handleShowSendSms}
              >
                <SmsSvg />
              </button>
            </Tooltip>
          )}
          {isPermission?.sendEmail?.action === "sendEmail" && (
            <Tooltip title="Send an Email">
              <button
                className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
                onClick={handleShowSendEmail}
              >
                <EmailSvg />
              </button>
            </Tooltip>
          )}
          {isPermission?.parcelGroupChangeCcyRate?.action ===
            "parcelGroupChangeCcyRate" &&
            isStatusSent && (
              <Tooltip title="Exchange rate change">
                <button
                  className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
                  onClick={handleShowChangeRate}
                >
                  <DollarSvg />
                </button>
              </Tooltip>
            )}
          {(isPermission?.parcelGroupChangeStatusS?.action ===
            "parcelGroupChangeStatusS" ||
            isPermission?.parcelGroupChangeStatusP?.action ===
              "parcelGroupChangeStatusP" ||
            isPermission?.parcelGroupChangeStatusR?.action ===
              "parcelGroupChangeStatusR") &&
            allStatusIsSame && (
              <Tooltip title="Change Status">
                <button
                  className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
                  onClick={handleShowChangeStatus}
                >
                  <RoleChangeSvg />
                </button>
              </Tooltip>
            )}
        </div>
      )}
    </div>
  );
};

export default GroupsPropertisBar;
