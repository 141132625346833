import { ReactBarcode as Barcode } from "react-jsbarcode";
import logo from "../../../assets/space-cargo-logo-2.svg";

const ParcelPrint = ({ printRef, data, formData, length, index, details }) => {
  const storedId = localStorage.getItem("warehouseCountryId");
  const barcodeValue = formData?.tds_code || data?.tdsCode || "DEFAULT";

  return (
    <div className="hidden w-full h-full" id={`print-content-${index}`}>
      <div ref={printRef} className="content">
        <img className="max-w-xxl w-full" src={logo} alt="Space Cargo" />
        <h2 className="text-lg font-semibold text-blue-900 mb-2">
          {data.fullName}
        </h2>
        {storedId === "210" && data.warehouseComment && (
          <p>{data.warehouseComment}</p>
        )}
        {details === "details" && (
          <span>
            {length} - {index + 1}
          </span>
        )}
        <span className="text-sm text-gray-700">{data.roomNumber}</span>
        <div
          style={{
            maxWidth: "1000px",
            width: "100%",
            height: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Barcode
            value={barcodeValue}
            format="CODE128"
            displayValue={true}
            lineColor="#000"
            width={2}
            height={40}
            textAlign="center"
            margin={10}
          />
        </div>
      </div>
    </div>
  );
};

export default ParcelPrint;
