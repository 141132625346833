import axios from "axios";

const getSystemPrices = async (
  token,
  fromCountryId,
  toCountryId,
  userTypeId,
  price,
  transportationTypeId,
  parcelGroupTypeId,
  page,
  perPage
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    fromCountryId: fromCountryId,
    toCountryId: toCountryId,
    userTypeId: userTypeId,
    price: price,
    transportationTypeId: transportationTypeId,
    parcelGroupTypeId: parcelGroupTypeId,
    page: page,
    perPage: perPage,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel/get_system_prices`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const addSystemPrice = async (systemPriceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel/add_system_price`,
    systemPriceData,
    config
  );

  return response.data;
};

const editSystemPrice = async (systemPriceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel/change_system_price`,
    systemPriceData,
    config
  );

  return response.data;
};

const deleteSystemPrice = async (id, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.post(
    `${process.env.REACT_APP_URI}/parcel/delete_system_price`,
    id,
    config
  );

  return response.data;
};

const systemPricesService = {
  addSystemPrice,
  editSystemPrice,
  getSystemPrices,
  deleteSystemPrice,
};

export default systemPricesService;
