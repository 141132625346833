import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { registerGroup, getGroups } from "../../../features/groups/GroupsSlice";
import { useAppContext } from "../../../libs/AppContext";
import { getTransportationTypes } from "../../../features/prices/getTransportationTypes/GetTransportationTypesSlice";
import { getParcelGroupTypes } from "../../../features/groups/GroupTypesSlice";
import ButtonDefault from "../../buttons/ButtonDefault";
import Input from "../../common/Input";
import ModalsHeadline from "../components/ModalsHeadline";
import SelectToSetLarge from "../../common/shortList/SelectToSetLarge";

const GroupsModal = ({
  closeModal,
  setShowModal,
  setShowResponseMessage,
  setResponseMessage,
  setSeverity,
  storedId,
}) => {
  const [formData, setFormData] = useState({
    barcode: "",
    warehouseCountryId: "",
    flightId: "",
    departmentId: "",
    parcelGroupTypeId: "",
    transportationTypeId: "",
  });
  const [errors, setErrors] = useState({
    barcode: "",
    parcelGroupTypeId: "",
    transportationTypeId: "",
  });
  const [countryName, setCountryName] = useState("");
  const { countries } = useSelector((state) => state.parcelCountries);
  const { departments } = useSelector((state) => state.parcelDepartments);
  const { flights } = useSelector((state) => state.parcelFlights);
  const { transportationTypes } = useSelector(
    (state) => state.transportationTypesForPrice
  );
  const { parcelGroupTypes } = useSelector((state) => state.parcelGroupsTypes);
  const {
    groupsCountryId,
    groupsFormData,
    setGroupsFormData,
    setNumbersSearchValue,
    setFlightsSearchValue,
  } = useAppContext();

  const {
    barcode,
    warehouseCountryId,
    flightId,
    departmentId,
    parcelGroupTypeId,
    transportationTypeId,
  } = formData;

  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getTransportationTypes());
    dispatch(getParcelGroupTypes());
  }, [dispatch]);

  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      warehouseCountryId: groupsCountryId,
    }));
  }, [groupsCountryId]);

  useEffect(() => {
    const foundCountry = countries?.find(
      (item) => item.countryId === groupsCountryId
    );
    if (foundCountry) {
      setCountryName(foundCountry.countryDictionaryKey);
    }
  }, [countries, groupsCountryId]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      warehouseCountryId: groupsCountryId,
    }));
  }, [storedId, groupsCountryId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleAddGroup = async (e) => {
    e.preventDefault();

    if (!barcode || !parcelGroupTypeId || !transportationTypeId) {
      setErrors({
        barcode: barcode ? "" : "Barcode is required",
        parcelGroupTypeId: parcelGroupTypeId
          ? ""
          : "Parcel Group Type is required",
        transportationTypeId: transportationTypeId
          ? ""
          : "Transportation Type is required",
      });
      return;
    }

    const groupData = {
      barcode,
      warehouseCountryId,
      flightId,
      departmentId,
      parcelGroupTypeId,
      transportationTypeId,
    };
    try {
      const response = await dispatch(registerGroup(groupData));
      if (response.payload.type === "success") {
        dispatch(getGroups(groupsFormData));
        setShowModal(false);
        setShowResponseMessage(true);
        setSeverity("success");
        setResponseMessage(response.payload.message);
        setFormData({
          barcode: "",
          warehouseCountryId: groupsCountryId,
          flightId: "",
          departmentId: "",
          parcelGroupTypeId: "",
          transportationTypeId: "",
        });
        setGroupsFormData((prev) => ({
          ...prev,
          flightNumber: "",
          barcode: "",
        }));
        setNumbersSearchValue("");
        setFlightsSearchValue("");
      }

      if (response.error.message === "Rejected") {
        setShowModal(false);
        setShowResponseMessage(true);
        setResponseMessage(response.payload.message);
        setSeverity("error");
      }
    } catch (error) {
      if (error.response && error.response.data) {
        throw new Error(error);
      }
    }
  };

  return (
    <form
      onSubmit={handleAddGroup}
      className="flex flex-col justify-between rounded-lg h-full"
    >
      <div>
        <ModalsHeadline text="Add parcel group" onClick={closeModal} />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="text-sm font-medium text-blue-900">
            {t(countryName)}
          </div>
          <div className="relative w-full">
            <Input
              text="List No"
              name="barcode"
              value={formData.barcode}
              onchange={handleInputChange}
              error={errors.barcode}
              errormark={errors.barcode ? "error-border" : ""}
            />
            {errors.barcode && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.barcode}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={flights?.map((cat) => ({
                value: cat.id,
                label: cat.flightNumber,
              }))}
              text="Flight No"
              name="flightId"
              value={formData.flightId}
              onChange={handleInputChange}
              error={errors.flightId}
            />
            {errors.flightId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.flightId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={departments?.map((cat) => ({
                value: cat.id,
                label: cat.address,
              }))}
              text="Branch"
              name="departmentId"
              value={formData.departmentId}
              onChange={handleInputChange}
              error={errors.departmentId}
            />
            {errors.departmentId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.departmentId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={parcelGroupTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Parcel Group Type"
              name="parcelGroupTypeId"
              value={formData.parcelGroupTypeId}
              onChange={handleInputChange}
              error={errors.parcelGroupTypeId}
            />
            {errors.parcelGroupTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.parcelGroupTypeId}
              </p>
            )}
          </div>
          <div className="relative w-full">
            <SelectToSetLarge
              options={transportationTypes?.map((cat) => ({
                value: cat.id,
                label: t(cat.dictionaryKey),
              }))}
              text="Transportation Type"
              name="transportationTypeId"
              value={formData.transportationTypeId}
              onChange={handleInputChange}
              error={errors.transportationTypeId}
            />
            {errors.transportationTypeId && (
              <p className="text-xs font-normal text-red-500 bg-white absolute -bottom-2 left-4">
                {errors.transportationTypeId}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault type="submit">{t("text.add")}</ButtonDefault>
      </div>
    </form>
  );
};

export default GroupsModal;
