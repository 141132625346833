import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppContext } from "../../libs/AppContext";
import { Alert, Snackbar } from "@mui/material";
import Modal from "../common/Modal";
import EditGroup from "../modals/groups/EditGroup";
import AddGroup from "../modals/groups/AddGroup";
import GroupsPropertisBar from "../table/groups/GroupsPropertisBar";
import GroupsTable from "../table/groups/GroupsTable";
import Message from "../../components/common/Message";
import DeleteConfirmation from "../common/DeleteConfirmation";
import ChangeRate from "../modals/groups/ChangeRate";
import SendEmail from "../modals/groups/SendEmail";
import SendSms from "../modals/groups/SendSms";
import ChangeStatus from "../modals/groups/ChangeStatus";
import AddButton from "../common/AddButton";
import { deleteGroup, getGroups } from "../../features/groups/GroupsSlice";
import { useTranslation } from "react-i18next";
import { getFlights } from "../../features/groups/flights/FlightsSlice";
import { getStatuses } from "../../features/groups/statuses/StatusesSlice";

const GroupsLayout = ({
  currentPage,
  onNextPage,
  perPage,
  onPrevPage,
  handlePerPage,
  handlePageClick,
}) => {
  // start forms
  const [showChangeRate, setShowChangeRate] = useState(false);
  const [showEditGroup, setShowEditGroup] = useState(false);
  const [showSendSms, setShowSendSms] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [showChangeStatus, setShowChangeStatus] = useState(false);
  // end forms
  const [severity, setSeverity] = useState("");
  const [allStatusIsSame, setAllStatusIsSame] = useState(false);
  const [isStatusSent, setIsStatusSent] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [groupId, setGroupId] = useState("");
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [responseMessage, setResponseMessage] = useState("");
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [changeStatusForm, setChangeStatusForm] = useState({
    parcelGroupIds: [],
    status: "",
  });
  const { parcelGroups } = useSelector((state) => state.groups?.groups);
  const {
    setShowMainModal,
    showMainModal,
    handleShowMainModal,
    groupsCountryId,
    groupsFormData,
    groupsIds,
    setGroupsIds,
    isPermission,
  } = useAppContext();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const storedId = localStorage.getItem("selectedCountryId");

  useEffect(() => {
    dispatch(getStatuses());
    if (groupsCountryId !== "") {
      dispatch(getFlights(groupsCountryId));
    }
  }, [dispatch, groupsCountryId]);

  useEffect(() => {
    setChangeStatusForm((prev) => ({
      ...prev,
      parcelGroupIds: groupsIds,
    }));
  }, [groupsIds]);

  useEffect(() => {
    setSeverity("");
  }, [storedId]);

  useEffect(() => {
    const selectedParcelGroups = selectedRows.map((id) =>
      parcelGroups.find((group) => group.parcelGroupId === id)
    );

    const uniqueStatuses = new Set(
      selectedParcelGroups?.map((row) => row?.status)
    );
    const currentStatus = uniqueStatuses.values().next().value;

    if (uniqueStatuses.size === 1 && currentStatus !== "R") {
      setAllStatusIsSame(true);
    } else {
      setAllStatusIsSame(false);
    }

    if (uniqueStatuses.size === 1 && currentStatus === "S") {
      setIsStatusSent(true);
    } else {
      setIsStatusSent(false);
    }
  }, [parcelGroups, selectedRows]);

  const handleShowEditGroup = (id) => {
    setShowEditGroup(!showEditGroup);
    setGroupId(id);
  };

  const handleShowChangeRate = () => {
    setShowChangeRate(!showChangeRate);
  };

  const handleShowSendSms = () => {
    setShowSendSms(!showSendSms);
  };

  const handleShowSendEmail = () => {
    setShowSendEmail(!showSendEmail);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
    setGroupId(id);
  };

  const handleShowChangeStatus = () => {
    if (selectedRows.length === 0) {
      return;
    }

    const selectedParcelGroups = selectedRows.map((id) =>
      parcelGroups.find((group) => group.parcelGroupId === id)
    );

    const uniqueStatuses = new Set(
      selectedParcelGroups?.map((row) => row.status)
    );

    if (uniqueStatuses.size !== 1) {
      return;
    }

    const currentStatus = uniqueStatuses.values().next().value;

    let targetStatus;
    switch (currentStatus) {
      case "A":
        targetStatus = "S";
        break;
      case "S":
        targetStatus = "P";
        break;
      case "P":
        targetStatus = "R";
        break;
      default:
        throw new Error("Unsupported status:", currentStatus);
        return;
    }
    const permissionAction = `parcelGroupChangeStatus${targetStatus}`;
    if (!isPermission[permissionAction]?.action === permissionAction) {
      return;
    }

    setChangeStatusForm((prev) => ({
      ...prev,
      status: targetStatus,
    }));

    setShowChangeStatus(true);
  };

  const handleDeleteGroup = async () => {
    const groupsData = {
      parcelGroupIds: groupsIds,
    };

    const response = await dispatch(deleteGroup(groupsData));
    if (response.payload.type === "success") {
      dispatch(getGroups(groupsFormData));
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setGroupsIds([]);
      setSelectedRows([]);
      setResponseMessage(response.payload.message);
    }

    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setGroupsIds([]);
      setSelectedRows([]);
      setResponseMessage(response.payload.message);
    }
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  return (
    <div>
      {showResponseMessage && (
        // <Snackbar
        //   open={showResponseMessage}
        //   autoHideDuration={5000}
        //   onClose={handleSnackbarClose}
        //   message={responseMessage}
        // />
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {responseMessage}
          </Alert>
        </Snackbar>
      )}
      <Modal showmodal={showMainModal} setShowModal={setShowMainModal}>
        <AddGroup
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowModal={setShowMainModal}
          closeModal={handleShowMainModal}
          setSeverity={setSeverity}
          storedId={storedId}
        />
      </Modal>
      <Modal showmodal={showEditGroup} setShowModal={setShowEditGroup}>
        <EditGroup
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setShowEditGroup={setShowEditGroup}
          setSeverity={setSeverity}
          groupId={groupId}
          storedId={storedId}
        />
      </Modal>
      <Modal showmodal={showChangeRate} setShowModal={setShowChangeRate}>
        <ChangeRate
          setShowChangeRate={setShowChangeRate}
          closeModal={handleShowChangeRate}
          groupsIds={groupsIds}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
        />
      </Modal>
      <Modal showmodal={showSendSms} setShowModal={setShowSendSms}>
        <SendSms
          setShowSendSms={setShowSendSms}
          closeModal={handleShowSendSms}
          groupsIds={groupsIds}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
        />
      </Modal>
      <Modal showmodal={showSendEmail} setShowModal={setShowSendEmail}>
        <SendEmail
          setShowSendEmail={setShowSendEmail}
          closeModal={handleShowSendEmail}
          groupsIds={groupsIds}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
        />
      </Modal>
      {showChangeStatus && (
        <ChangeStatus
          setShowChangeStatus={setShowChangeStatus}
          changeStatusForm={changeStatusForm}
          setResponseMessage={setResponseMessage}
          setShowResponseMessage={setShowResponseMessage}
          setGroupsIds={setGroupsIds}
          setSelectedRows={setSelectedRows}
        />
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteGroup}
          text={t("text.groups")}
        />
      )}
      <GroupsPropertisBar
        handleShowChangeRate={handleShowChangeRate}
        handleShowSendSms={handleShowSendSms}
        handleShowSendEmail={handleShowSendEmail}
        handleShowChangeStatus={handleShowChangeStatus}
        handleDeleteConfirmation={handleDeleteConfirmation}
        allStatusIsSame={allStatusIsSame}
        isStatusSent={isStatusSent}
        groupsIds={groupsIds}
        // setExportLanguage={setExportLanguage}
      />

      {storedId ? (
        <>
          <GroupsTable
            handleShowEditGroup={handleShowEditGroup}
            setGroupsIds={setGroupsIds}
            groupsIds={groupsIds}
            setSelectedRows={setSelectedRows}
            selectedRows={selectedRows}
            parcelGroups={parcelGroups}
            currentPage={currentPage}
            onNextPage={onNextPage}
            perPage={perPage}
            onPrevPage={onPrevPage}
            handlePerPage={handlePerPage}
            handlePageClick={handlePageClick}
            // language={exportLanguage}
          />
          <AddButton />
        </>
      ) : (
        <Message text={t("text.select_country")} />
      )}
    </div>
  );
};

export default GroupsLayout;
