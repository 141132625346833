import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAppContext } from "../../../libs/AppContext";
import { Link } from "react-router-dom";
import BarcodeModal from "../../modals/groups/BarcodeModal";
import Spinner from "../../common/Spinner";
import Tooltip from "@mui/material/Tooltip";
import Checkbox from "../../common/Checkbox";
// import Pagination from "../../common/Pagination";
import CheckAll from "../../common/CheckAll";
import TableSearch from "../../common/TableSearch";
import TableSearchSelect from "../../common/TableSearchSelect";
import exportUsaExcel from "../../../assets/export-excel.svg";
import Message from "../../common/Message";
import {
  BarcodeSvg,
  DetailsPageSvg,
  EditSvg,
  ExportFileSvg,
  UploadDocumentSvg,
} from "../../icons";
import {
  exportParcels,
  exportUsaParcels,
  getBarcode,
} from "../../../features/groups/GroupsSlice";
import Pagination from "../../common/Pagination";

const ParcelGroupsTable = ({
  groupsIds,
  handleShowEditGroup,
  selectedRows,
  setSelectedRows,
  setGroupsIds,
  parcelgroupsdata,
  setparcelgroupsdata,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [barcodeImageValue, setBarcodeImageValue] = useState("");
  const [departmentMap, setDepartmentMap] = useState({});
  const [selectedStatus, setSelectedStatus] = useState("");
  const [recordsNumber, setRecordsNumber] = useState("");
  const [selectedRate, setSelectedRate] = useState("₾");
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);

  const { departments } = useSelector((state) => state.parcelDepartments);
  const { statuses } = useSelector((state) => state.parcelStatuses);
  const { groups, isLoading, isSuccess } = useSelector(
    (state) => state.parcelGroups
  );

  const { isPermission } = useAppContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const columns = [
    { header: t("text.flights"), key: "flightNumber" },
    { header: t("text.barcode"), key: "barcode" },
    { header: t("text.department"), key: "departmentId" },
    { header: "T.W", key: "totalWeight" },
    { header: "Total Volume weight", key: "totalVolumeWeight" },
    { header: "Transportation Type", key: "transportationTypeDesc" },
    { header: "Parcel Group Type", key: "parcelGroupTypeDesc" },
    { header: "T.Q", key: "totalCount" },
    {
      header: t("text.amount"),
      key: `${selectedRate === "₾" ? "totalAmountGel" : "totalAmountUsd"}`,
    },
    { header: "A.P", key: "payedAmountGel" },
    { header: "%", key: "payedAmountPercentage" },
    { header: t("text.ccy_rate"), key: "ccyRate" },
    { header: t("text.status"), key: "status" },
  ];

  useEffect(() => {
    setRecordsNumber(groups?.recordsNumber);
  }, [groups?.recordsNumber]);

  useEffect(() => {
    const departmentMapping = {};
    departments.forEach((department) => {
      departmentMapping[department.id] = department.address;
    });
    setDepartmentMap(departmentMapping);
  }, [departments]);

  useEffect(() => {
    setGroupsIds(selectedRows);
  }, [selectedRows, setGroupsIds]);

  useEffect(() => {
    const status = statuses?.find(
      (item) => item?.status === parcelgroupsdata?.status
    );
    if (status) {
      setSelectedStatus(status?.statusDictionaryKey);
    }
  }, [parcelgroupsdata, statuses]);

  const handleBarcode = (barcodeValue) => {
    setBarcodeImageValue(barcodeValue);
    if (barcodeValue) {
      dispatch(getBarcode(barcodeValue));
    }
    setShowModal(!showModal);
  };

  const handleExport = async (id) => {
    const language =
      JSON.parse(localStorage.getItem("language"))?.code?.toUpperCase() ||
      "DEFAULT_LANGUAGE_CODE";

    try {
      const action = await dispatch(
        exportParcels({ parcelGroupId: id, language })
      );
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const handleExportUsa = async (id) => {
    const language =
      JSON.parse(localStorage.getItem("language"))?.code?.toUpperCase() ||
      "DEFAULT_LANGUAGE_CODE";

    try {
      const action = await dispatch(
        exportUsaParcels({ parcelGroupId: id, language })
      );
      const { file, fileName } = action.payload.message;
      const decodedData = atob(file);
      const byteArray = new Uint8Array(decodedData?.length);
      for (let i = 0; i < decodedData?.length; i++) {
        byteArray[i] = decodedData.charCodeAt(i);
      }
      const blobData = new Blob([byteArray], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blobData);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Export failed:", error);
    }
  };

  const getStatusName = (statusSymbol, t, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? t(status.statusDictionaryKey) : "";
  };

  const getStatusClassName = (statusSymbol, statuses) => {
    const status = statuses?.find((s) => s.status === statusSymbol);
    return status ? status.status.toLowerCase() : "";
  };

  const getDepartmentName = (departmentId) => {
    return departmentMap[departmentId] || "";
  };

  const calculateTotals = (selectedRows, groups) => {
    let totalWeight = 0;
    let totalQuantity = 0;

    selectedRows.forEach((id) => {
      const row = groups?.parcelGroups?.find(
        (group) => group.parcelGroupId === id
      );
      if (row) {
        totalWeight += row.totalWeight || 0;
        totalQuantity += row.totalCount || 0;
      }
    });

    setTotalWeight(totalWeight.toString());
    setTotalQuantity(totalQuantity.toString());
  };

  const handleSelectRows = (parcelGroupId) => {
    const isSelected = selectedRows.includes(parcelGroupId);
    const updatedSelectedRows = isSelected
      ? selectedRows.filter((id) => id !== parcelGroupId)
      : [...selectedRows, parcelGroupId];

    setSelectedRows(updatedSelectedRows);
    calculateTotals(updatedSelectedRows, groups);
  };

  const handleCheckAllRow = () => {
    if (selectedRows?.length > 0) {
      setSelectedRows([]);
      setGroupsIds([]);
      setTotalWeight(0);
      setTotalQuantity(0);
    } else {
      const allParcelGroups = groups?.parcelGroups
        ? groups?.parcelGroups?.map((row) => row.parcelGroupId)
        : [];

      setSelectedRows(allParcelGroups);
      calculateTotals(allParcelGroups, groups);
    }
  };

  // const handleSelectRows = (parcelGroupId) => {
  //   const isSelected = selectedRows.includes(parcelGroupId);
  //   if (isSelected) {
  //     setSelectedRows(selectedRows.filter((id) => id !== parcelGroupId));
  //   } else {
  //     setSelectedRows([...selectedRows, parcelGroupId]);
  //   }
  // };

  // const handleCheckAllRow = () => {
  //   if (selectedRows?.length > 0) {
  //     setSelectedRows([]);
  //     setGroupsIds([]);
  //   } else {
  //     const allParcelGroups = groups?.parcelGroups
  //       ? groups?.parcelGroups?.map((row) => row.parcelGroupId)
  //       : [];

  //     setSelectedRows(allParcelGroups);
  //   }
  // };

  const CheckboxComponent =
    groupsIds?.length === groups?.parcelGroups?.length ? Checkbox : CheckAll;

  const handleSaveParcelGroupId = (id) => {
    localStorage.setItem("parcelGroupId", id);
  };

  const handlePageIncrement = () => {
    setparcelgroupsdata((prevData) => ({
      ...prevData,
      page: parcelgroupsdata.page + 1,
    }));
  };

  const handlePageDecrement = () => {
    if (parcelgroupsdata.page > 1) {
      setparcelgroupsdata((prevData) => ({
        ...prevData,
        page: parcelgroupsdata.page - 1,
      }));
    }
  };

  const handlePageClick = (pageNumber) => {
    setparcelgroupsdata((prevData) => ({ ...prevData, page: pageNumber }));
  };

  const handlePerPage = (e) => {
    setparcelgroupsdata((prevData) => ({ ...prevData, perPage: e }));
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isSuccess && (
            <div className="w-full flex flex-col justify-between gap-20 h-[calc(100vh-77px)]">
              <table className="w-full bg-white border-collapse">
                <thead className="bg-gray-100 text-sm text-blue-4">
                  <tr>
                    <th className="w-12 p-4 text-left border-b border-r border-black/5 font-medium">
                      <div className="flex justify-center">
                        <CheckboxComponent
                          checked={groupsIds?.length > 0}
                          onChange={handleCheckAllRow}
                        />
                      </div>
                    </th>
                    {columns?.map((column, index) => (
                      <th
                        key={index}
                        className={`${
                          (column.key === "flightNumber" ||
                            column.key === "barcode" ||
                            column.key === "actions") &&
                          "min-w-10"
                        } max-w-5 text-left border-b font-medium`}
                      >
                        <div
                          className={`relative p-4 w-full border-r border-black/5 ${
                            column.key === "departmentId" ? "truncate" : ""
                          }`}
                        >
                          {(column.key === "flightNumber" ||
                            column.key === "barcode") && (
                            <>
                              <span className="block truncate pr-3">
                                {column.header}
                              </span>
                              <TableSearch
                                formData={parcelgroupsdata}
                                setFormData={setparcelgroupsdata}
                                searchKey={column.key}
                              />
                            </>
                          )}
                          {column.header === t("text.amount") && (
                            <div className="relative flex justify-between items-center gap-2">
                              <span className="truncate">{column.header}</span>
                              <select
                                className="absolute cursor-pointer -right-4 bg-transparent border border-none outline-none rounded"
                                value={selectedRate}
                                onChange={(e) =>
                                  setSelectedRate(e.target.value)
                                }
                              >
                                <option value="₾">₾</option>
                                <option value="$">$</option>
                              </select>
                            </div>
                          )}
                          {column.key === "status" && (
                            <div className="relative flex justify-between items-center gap-2">
                              <span className="truncate">{column.header}</span>
                              <TableSearchSelect
                                setFormData={setparcelgroupsdata}
                                formData={parcelgroupsdata}
                                data={statuses}
                                selectedValue={selectedStatus}
                                searchKey={column.key}
                                translationKey="statusDictionaryKey"
                                valueKey="status"
                              />
                            </div>
                          )}
                          {column.key !== "status" &&
                            column.header !== t("text.amount") &&
                            column.key !== "barcode" &&
                            column.key !== "flightNumber" && (
                              <Tooltip title={column.header}>
                                <span className="block truncate">
                                  {column.header}
                                </span>
                              </Tooltip>
                            )}
                          {column.key === "totalCount" && totalQuantity > 0 && (
                            <span className="text-xs ">{totalQuantity}</span>
                          )}
                          {column.key === "totalWeight" && totalWeight > 0 && (
                            <span className="text-xs">{totalWeight}</span>
                          )}
                        </div>
                      </th>
                    ))}
                    <th className="max-w-2 text-left border-b font-medium w-20 min-w-20">
                      <div className="p-4 w-full truncate">
                        {t("text.actions")}
                      </div>
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {groups && groups?.parcelGroups?.length > 0 ? (
                    groups?.parcelGroups?.map((row, index) => (
                      <tr
                        key={index}
                        className="hover:bg-gray-50 border-black/5 border-b"
                      >
                        <td className="py-2 px-3 border-r border-black/5 flex justify-center">
                          <Checkbox
                            checked={
                              groupsIds?.length > 0 &&
                              selectedRows.includes(row.parcelGroupId)
                            }
                            onChange={() => handleSelectRows(row.parcelGroupId)}
                          />
                        </td>
                        {columns?.map((column) => (
                          <td
                            key={column.key}
                            data-label={column.key}
                            className="max-w-5 text-xs text-blue-4 font-medium"
                          >
                            {column.key === "status" ? (
                              <div
                                className={`py-2 px-3 w-full truncate border-r border-black/5`}
                              >
                                <Tooltip
                                  title={getStatusName(
                                    row[column.key],
                                    t,
                                    statuses
                                  )}
                                >
                                  <span
                                    className={`${getStatusClassName(
                                      row[column.key],
                                      statuses
                                    )} status truncate px-2`}
                                  >
                                    {getStatusName(
                                      row[column.key],
                                      t,
                                      statuses
                                    )}
                                  </span>
                                </Tooltip>
                              </div>
                            ) : column.key === "departmentId" ? (
                              <div className="py-2 px-3 w-full truncate border-r border-black/5">
                                <Tooltip
                                  title={getDepartmentName(row[column.key])}
                                >
                                  <span className="w-full">
                                    {getDepartmentName(row[column.key])}
                                  </span>
                                </Tooltip>
                              </div>
                            ) : column.key === "transportationTypeDesc" ||
                              column.key === "parcelGroupTypeDesc" ? (
                              <div className="py-2 px-3 w-full truncate border-r border-black/5">
                                <Tooltip title={t(row[column.key])}>
                                  <span className="w-full">
                                    {t(row[column.key])}
                                  </span>
                                </Tooltip>
                              </div>
                            ) : (
                              <div className="py-2 px-3 w-full truncate border-r border-black/5">
                                <Tooltip title={row[column.key]}>
                                  <span>{row[column.key]}</span>
                                </Tooltip>
                              </div>
                            )}
                          </td>
                        ))}
                        <td className="py-2 px-3 border-r border-black/5 w-40 flex justify-center">
                          <div className="flex gap-2">
                            {isPermission.parcelGroupShowParcels?.action ===
                              "parcelGroupShowParcels" && (
                              <Tooltip title={t("text.parcelGroupShowParcels")}>
                                <Link
                                  to={`/ParcelGroupNew/${row?.parcelGroupId}/Parcels`}
                                  onClick={(e) =>
                                    handleSaveParcelGroupId(row.parcelGroupId)
                                  }
                                >
                                  <DetailsPageSvg />
                                </Link>
                              </Tooltip>
                            )}
                            <Tooltip title="TrackTrace">
                              <Link
                                to={`https://www.track-trace.com/aircargo#${row.barcode}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <ExportFileSvg />
                              </Link>
                            </Tooltip>
                            <Tooltip title={t("text.export")}>
                              <button
                                onClick={() => handleExport(row.parcelGroupId)}
                              >
                                <UploadDocumentSvg />
                              </button>
                            </Tooltip>
                            <Tooltip title={`${t("text.export")} USA`}>
                              <button
                                onClick={() =>
                                  handleExportUsa(row.parcelGroupId)
                                }
                              >
                                <img
                                  className="h-4 w-4 object-contain"
                                  src={exportUsaExcel}
                                  alt="excel"
                                />
                              </button>
                            </Tooltip>
                            <Tooltip title={t("text.barcode")}>
                              <button
                                onClick={() => handleBarcode(row.barcode)}
                              >
                                <BarcodeSvg />
                              </button>
                            </Tooltip>
                            {isPermission.parcelGroupChange?.action ===
                              "parcelGroupChange" && (
                              <Tooltip title={t("text.edit")}>
                                <button
                                  onClick={() =>
                                    handleShowEditGroup(row.parcelGroupId)
                                  }
                                >
                                  <EditSvg />
                                </button>
                              </Tooltip>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan={Object.keys(columns)?.length + 2}
                        className="border-none p-2"
                      >
                        <Message text={t("text.not_found")} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              {recordsNumber > 1 && (
                <Pagination
                  recordsNumber={recordsNumber}
                  currentPage={parcelgroupsdata.page}
                  perPage={parcelgroupsdata.perPage}
                  onNextPage={handlePageIncrement}
                  onPrevPage={handlePageDecrement}
                  handlePerPage={handlePerPage}
                  handlePageClick={handlePageClick}
                />
              )}
            </div>
          )}
          {showModal && (
            <BarcodeModal
              setShowModal={setShowModal}
              setBarcodeImageValue={setBarcodeImageValue}
              barcodeImageValue={barcodeImageValue}
            />
          )}
        </>
      )}
    </>
  );
};

export default ParcelGroupsTable;
