import { useEffect, useState } from "react";
import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ArrowDownSvg, WorldSvg } from "../icons";
import { useAppContext } from "../../libs/AppContext";

const CountriesSelect = () => {
  const { t } = useTranslation();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { setGroupsCountryId, setGroupsIds, setGroupsFormData } =
    useAppContext();
  const { countries } = useSelector((state) => state.parcelCountries);
  const countryId = localStorage.getItem("selectedCountryId");
  const currentPage = localStorage.getItem("currentPage");

  const handleCountryChange = (selectedCountry) => {
    if (!selectedCountry) return;

    setSelectedCountry(selectedCountry);
    setGroupsFormData((prev) => ({
      ...prev,
      status: "",
    }));
    localStorage.setItem("selectedCountryId", selectedCountry.countryId);
    setGroupsCountryId(selectedCountry.countryId);
    setGroupsIds([]);
    if (currentPage) {
      setGroupsFormData((prev) => ({
        ...prev,
        page: 1,
      }));
    }
  };

  useEffect(() => {
    const storedCountry = countries?.find(
      (country) => parseInt(country.countryId) === parseInt(countryId)
    );

    setSelectedCountry(storedCountry || null);
  }, [countryId, countries]);

  return (
    <div className="relative min-w-44 z-20">
      <Listbox value={selectedCountry || {}} onChange={handleCountryChange}>
        <ListboxButton className="w-full py-1.5 px-3 h-8 rounded border border-[#09244b1a] bg-white flex justify-between items-center gap-2 cursor-pointer">
          <WorldSvg />
          <span className="text-blue-4 text-sm font-normal">
            {selectedCountry && selectedCountry.countryDictionaryKey
              ? t(selectedCountry.countryDictionaryKey)
              : t("text.select_country")}
          </span>
          <ArrowDownSvg className="min-w-4" />
        </ListboxButton>
        <ListboxOptions className="w-full absolute bg-white top-full mt-1 rounded border border-[#09244b1a] py-1">
          {countries?.length > 0 ? (
            countries.map((country) => (
              <ListboxOption
                key={country.countryId}
                value={country}
                className="transition-all duration-500 ease-in-out px-2 py-1 text-left text-xs font-normal text-[#2e4566] hover:bg-[#416ff414] cursor-pointer"
              >
                {t(country.countryDictionaryKey)}
              </ListboxOption>
            ))
          ) : (
            <div className="px-2 py-1 text-xs text-blue-4">
              {t("text.not_found")}
            </div>
          )}
        </ListboxOptions>
      </Listbox>
    </div>
  );
};

export default CountriesSelect;
