import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import citiesService from "./CitiesService";

const initialState = {
  cities: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getCities = createAsyncThunk(
  "courier/get_cities",
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await citiesService.getCities(token);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const citiesSlice = createSlice({
  name: "cities",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCities.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.cities = action.payload;
      })
      .addCase(getCities.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = citiesSlice.actions;
export default citiesSlice.reducer;
