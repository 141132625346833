import axios from "axios";

const getDepartments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_departments`,
    config
  );

  return response.data;
};

const departmentsService = { getDepartments };
export default departmentsService;
