import { useState, useEffect, useRef } from "react";
import { ArrowDownSvg, CloseModalSvg } from "../../icons";

const SelectToSetLarge = ({ options, text, name, value, onChange, error }) => {
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [searchTerm, setSearchTerm] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearchChange = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
    const filtered = options.filter((option) =>
      option.label.toLowerCase().includes(term)
    );
    setFilteredOptions(filtered);
  };

  const handleSelectChange = (value) => {
    onChange({ target: { name, value } });
    setShowDropdown(false);
    setSearchTerm("");
  };

  const handleClickOutside = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setShowDropdown(false);
    }
  };

  const handleClearValue = (e) => {
    e.stopPropagation();
    onChange({ target: { name, value: "" } });
    setSearchTerm("");
  };

  return (
    <div className="relative w-full" ref={dropdownRef}>
      <div
        className={`relative cursor-pointer w-full px-4 py-4 border flex items-center justify-between  ${
          error ? "border-red-500" : "border-gray-200"
        } bg-white rounded-lg text-sm`}
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {value ? (
          options.find((option) => option.value === value)?.label
        ) : (
          <span className="text-gray-1 text-xs md:text-sm">Select {text}</span>
        )}

        <ArrowDownSvg />
      </div>

      {showDropdown && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-lg max-h-60 overflow-auto">
          <input
            type="text"
            className="w-full text-sm px-4 py-3 border-b border-gray-300 outline-none"
            placeholder="Search..."
            value={searchTerm}
            onChange={handleSearchChange}
          />

          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <div
                key={index}
                onClick={() => handleSelectChange(option.value)}
                className={`flex justify-between items-center gap-1 cursor-pointer px-4 py-2 text-xs md:text-sm hover:bg-blue-2/10 ${
                  value === option.value ? "bg-blue-2/10 font-semibold" : ""
                }`}
              >
                {option.label}
                {value === option.value && (
                  <span onClick={(e) => handleClearValue(e, name)}>
                    <CloseModalSvg />
                  </span>
                )}
              </div>
            ))
          ) : (
            <div className="p-2 text-gray-500 text-xs md:text-sm">
              No options found
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SelectToSetLarge;
