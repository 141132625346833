import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { SearchSvg, CloseModalSvg } from "../../icons";

const TableSearch = ({ formData, setFormData, searchKey, parcels }) => {
  const [searchTerm, setSearchTerm] = useState(formData[searchKey] || "");
  const [activeSearch, setActiveSearch] = useState(false);
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setActiveSearch(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleActiveSearch = () => {
    setActiveSearch(!activeSearch);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (parcels === "parcels") {
      setFormData((prevData) => ({
        ...prevData,
        [searchKey]: searchTerm,
        parcelsPage: 1,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [searchKey]: searchTerm,
        page: 1,
      }));
    }
    setActiveSearch(false);
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setFormData((prevData) => ({
      ...prevData,
      [searchKey]: "",
      page: 1,
    }));
    setActiveSearch(true);
  };

  const handleWrapperClick = (event) => {
    event.stopPropagation();
  };

  return (
    <form
      onSubmit={handleSubmit}
      onClick={handleWrapperClick}
      ref={inputRef}
      className={`${
        activeSearch
          ? "bg-white w-[calc(100%-32px)] border border-gray-1/15 rounded-xl"
          : "w-5"
      } absolute top-1/2 transform -translate-y-1/2 right-4 transition-all duration-300 text-sm`}
    >
      <div
        className={`${
          activeSearch ? "py-2 pl-4 pr-8" : ""
        } relative w-full transition-all duration-300`}
      >
        <input
          className={`${
            activeSearch ? "opacity-100" : "opacity-0"
          } bg-transparent outline-none text-base font-light w-full transition-all duration-300`}
          type="text"
          placeholder={t("text.search")}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button type="submit" className="absolute"></button>

        {searchTerm !== "" && activeSearch ? (
          <span
            className={`${
              activeSearch ? "right-2" : "-right-2"
            } absolute top-1/2 transform -translate-y-1/2 cursor-pointer transition-all duration-300 h-5 w-5 flex justify-center items-center`}
            onClick={handleClearSearch}
          >
            <CloseModalSvg />
          </span>
        ) : (
          <span
            onClick={handleActiveSearch}
            className={`${
              activeSearch ? "right-2" : "-right-2"
            } absolute top-1/2 transform -translate-y-1/2 cursor-pointer transition-all duration-300`}
          >
            <SearchSvg />
          </span>
        )}
      </div>
    </form>
  );
};

export default TableSearch;
