import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCountries } from "../../features/groups/countries/CountriesSlice";
import { getGroups } from "../../features/groups/groups/GroupsSlice";
import { getParcels } from "../../features/groups/parcels/ParcelsSlice";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../libs/AppContext";

const ParcelGroupsLayout = ({ children, setCountry }) => {
  // const [parcelgroupsdata, setparcelgroupsdata] = useState({
  //   warehouseCountryId: localStorage.getItem("warehouseCountryId") || "",
  //   flightNumber: "",
  //   status: "",
  //   barcode: "",
  //   page: 1,
  //   perPage: 10,
  // });

  const initialParcelGroupsData = JSON.parse(
    localStorage.getItem("parcelgroupsdata")
  ) || {
    warehouseCountryId: localStorage.getItem("warehouseCountryId") || "",
    flightNumber: "",
    status: "",
    barcode: "",
    page: 1,
    perPage: 10,
  };

  const [parcelgroupsdata, setparcelgroupsdata] = useState(
    initialParcelGroupsData
  );

  const [parcelsdata, setparcelsdata] = useState({
    parcelGroupId: "",
    tdsCode: "",
    roomNumber: "",
    page: 1,
    perPage: 10,
  });

  const dispatch = useDispatch();
  const { parcelGroupId } = useParams();
  const { refetch, parcelsRefetch } = useAppContext();
  const { groups } = useSelector((state) => state.groups);

  useEffect(() => {
    dispatch(getCountries());
  }, [dispatch]);

  useEffect(() => {
    setparcelsdata((prev) => ({
      ...prev,
      parcelGroupId: parcelGroupId,
    }));
  }, [parcelGroupId]);

  useEffect(() => {
    const handlePageRefresh = () => {
      const path = window.location.pathname;

      if (
        path.startsWith("/ParcelGroupNew") &&
        path.endsWith("/ParcelGroupNew")
      ) {
        localStorage.removeItem("warehouseCountryId");
        localStorage.removeItem("parcelgroupsdata");
        setparcelgroupsdata((prev) => ({
          ...prev,
          warehouseCountryId: "",
        }));
      }
    };
    window.addEventListener("beforeunload", handlePageRefresh);
    return () => {
      window.removeEventListener("beforeunload", handlePageRefresh);
    };
  }, []);

  useEffect(() => {
    if (parcelgroupsdata.warehouseCountryId) {
      localStorage.setItem(
        "parcelgroupsdata",
        JSON.stringify(parcelgroupsdata)
      );
    }
  }, [parcelgroupsdata]);

  useEffect(() => {
    if (parcelgroupsdata.warehouseCountryId) {
      if (setCountry) {
        setCountry(parcelgroupsdata.warehouseCountryId);
      }
      dispatch(
        getGroups({
          warehouseCountryId: parcelgroupsdata.warehouseCountryId,
          flightNumber: parcelgroupsdata.flightNumber,
          status: parcelgroupsdata.status,
          barcode: parcelgroupsdata.barcode,
          page: parcelgroupsdata.page,
          perPage: parcelgroupsdata.perPage,
        })
      );
    }
  }, [
    dispatch,
    setCountry,
    parcelgroupsdata.warehouseCountryId,
    parcelgroupsdata.flightNumber,
    parcelgroupsdata.status,
    parcelgroupsdata.barcode,
    parcelgroupsdata.page,
    parcelgroupsdata.perPage,
    groups?.parcelGroups?.length,
    refetch,
  ]);

  useEffect(() => {
    if (parcelsdata.parcelGroupId) {
      dispatch(
        getParcels({
          parcelGroupId: parcelsdata.parcelGroupId,
          tdsCode: parcelsdata.tdsCode,
          roomNumber: parcelsdata.roomNumber,
          page: parcelsdata.page,
          perPage: parcelsdata.perPage,
        })
      );
    }
  }, [
    dispatch,
    parcelsdata.parcelGroupId,
    parcelsdata.tdsCode,
    parcelsdata.roomNumber,
    parcelsdata.page,
    parcelsdata.perPage,
    parcelsRefetch,
  ]);

  return (
    <div>
      {React.Children.map(children, (child) =>
        React.isValidElement(child)
          ? React.cloneElement(child, {
              parcelgroupsdata,
              setparcelgroupsdata,
              parcelsdata,
              setparcelsdata,
            })
          : child
      )}
    </div>
  );
};

export default ParcelGroupsLayout;
