import { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";

const StatusSelect = ({ formData, setFormData, columnKey }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);
  const { t } = useTranslation();

  const options = [
    { label: t("Select All Status"), value: "" },
    { label: t("text.active"), value: "A" },
    { label: t("text.closed"), value: "C" },
  ];

  const handleSelect = (option) => {
    setFormData((prevData) => ({
      ...prevData,
      [columnKey]: option.value,
    }));
    setIsOpen(false);
  };

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div ref={dropdownRef} className="relative">
      <div
        className="w-2.5 cursor-pointer flex justify-end items-center transition-all duration-300 hover:text-blue-2"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        ▼
      </div>

      {isOpen && (
        <div className="absolute right-0 z-10 w-32 border bg-white-1 border-gray-300 rounded-md mt-1 shadow-md">
          {options.map((option) => (
            <div
              key={option.value}
              className={`flex items-center justify-between gap-2 text-sm p-1.5 w-full hover:bg-blue-2/10 cursor-pointer ${
                formData[columnKey] === option.value ? "bg-blue-2/10" : ""
              }`}
              onClick={() => handleSelect(option)}
            >
              <span className="truncate">{option.label}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default StatusSelect;
