import axios from "axios";

const getConfig = async (token, warehouseCountryId) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const data = {
    warehouseCountryId: warehouseCountryId,
  };

  const response = await axios.get(
    `${process.env.REACT_APP_URI}/parcel_group/get_parcel_group_config`,
    {
      ...config,
      params: data,
    }
  );

  return response.data;
};

const configService = { getConfig };
export default configService;
