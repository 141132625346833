import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import parcelsService from "./ParcelsService";

const initialState = {
  parcels: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getParcels = createAsyncThunk(
  "/parcel_group/get_parcels",
  async ({ parcelGroupId, tdsCode, roomNumber, page, perPage }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelsService.getParcels(
        token,
        parcelGroupId,
        tdsCode,
        roomNumber,
        page,
        perPage
      );
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const registerIndividualParcel = createAsyncThunk(
  "parcel_group/receive_parcel_new3",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelsService.registerIndividualParcel(token, parcelData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const updateIndividualParcel = createAsyncThunk(
  "parcel_group/change_parcel_new3",
  async (parcelData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await parcelsService.updateIndividualParcel(token, parcelData);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const parcelsSlice = createSlice({
  name: "parcels",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getParcels.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getParcels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parcels = action.payload;
      })
      .addCase(getParcels.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(registerIndividualParcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerIndividualParcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.parcels?.parcels.unshift(action.payload);
      })
      .addCase(registerIndividualParcel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(updateIndividualParcel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIndividualParcel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        const index = state.parcels.parcels.findIndex(
          (parcel) => parcel.id === action.payload.id
        );
        state.parcels.parcels[index] = action.payload;
      })
      .addCase(updateIndividualParcel.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { reset } = parcelsSlice.actions;
export default parcelsSlice.reducer;
