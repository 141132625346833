import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import configService from "./ConfigService";

const initialState = {
  config: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

export const getConfig = createAsyncThunk(
  "parcel_group/get_parcel_group_config",
  async ({ warehouseCountryId }, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.message.token;
      return await configService.getConfig(token, warehouseCountryId);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue({
        message,
      });
    }
  }
);

export const configSlice = createSlice({
  name: "groupConfig",
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.config = action.payload;
      })
      .addCase(getConfig.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.error.message || "change failed";
      });
  },
});

export const { reset } = configSlice.actions;
export default configSlice.reducer;
