import { useDispatch, useSelector } from "react-redux";
import { Alert, Snackbar, Tooltip } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { BackSvg, DeleteSvg, Rechange } from "../../components/icons";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { deleteParcels } from "../../features/parcels/ParcelsSlice";
import { useAppContext } from "../../libs/AppContext";
import ParcelGroupsLayout from "./ParcelGroupsLayout";
import addParcelImage from "../../assets/add-parcel.png";
import ParcelsTable from "../../components/table/groupsNew/ParcelsTable";
import Modal from "../../components/common/Modal";
import PicturesList from "../../components/modals/groupsNew/PicturesList";
import DeleteConfirmation from "../../components/common/DeleteConfirmation";
import ChangeGroupForParcel from "../../components/modals/groupsNew/ChangeGroupForParcel";

const ParcelsNew = () => {
  const [openedGroup, setOpenedGroup] = useState("");
  const [showPicturesList, setShowPicturesList] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [parcelId, setParcelId] = useState("");
  const [parcelIds, setParcelIds] = useState([]);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [showGroupChangeModal, setShowGroupChangeModal] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState("");
  const { groups } = useSelector((state) => state.parcelGroups);
  const { parcelGroupId } = useParams();
  const { setParcelsRefetch, parcelsRefetch } = useAppContext();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (groups.parcelGroups && parcelGroupId) {
      const item = groups?.parcelGroups?.find(
        (group) => parseInt(group?.parcelGroupId) === parseInt(parcelGroupId)
      );
      if (item) {
        setOpenedGroup(item);
      }
    }
  }, [groups, parcelGroupId]);

  const handleCloseImage = () => {
    setSelectedImage(null);
  };

  const handleShowPicturesList = (id) => {
    setShowPicturesList(true);
    setParcelId(id);
  };

  const handleDeleteConfirmation = (id) => {
    setDeleteConfirmation(!deleteConfirmation);
  };

  const handleShowRelocation = () => {
    setShowGroupChangeModal(!showGroupChangeModal);
  };

  const handleSnackbarClose = (reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowResponseMessage(false);
  };

  const handleDeleteParcels = async () => {
    const parcelsData = {
      parcelIds: parcelIds,
    };

    const response = await dispatch(deleteParcels(parcelsData));
    if (response.payload.type === "success") {
      setParcelsRefetch(!parcelsRefetch);
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setParcelIds([]);
      setSelectedRows([]);
      setSeverity("success");
      setMessage(response.payload.message);
    }
    if (response?.error?.message === "Rejected") {
      setDeleteConfirmation(false);
      setShowResponseMessage(true);
      setParcelIds([]);
      setSelectedRows([]);
      setSeverity("error");
      setMessage(response.payload.message);
    }
  };

  return (
    <ParcelGroupsLayout>
      <div className="flex items-center justify-between w-full bg-gray-100  gap-3 border-b border-gray-300 p-2 md:p-4">
        <div className="flex items-center gap-3">
          <Link
            to="/ParcelGroupNew"
            className="border border-black/10 p-3.5 rounded flex items-center justify-center h-11 w-11"
          >
            <BackSvg />
          </Link>
          {openedGroup && (
            <div className="text-2xl font-semibold text-gray-800">
              <span>{t("text._navigation.parcelGroups")} - </span>
              <span className="font-normal">{openedGroup?.barcode}</span>
            </div>
          )}
        </div>
        <div className="flex justify-end gap-2 items-center">
          {/* {openedGroup?.parcelGroupTypeId === 2 &&
          openedGroup.status === "a" ? (
            <Link
              to={`/ParcelGroupNew/${parcelGroupId}/Parcels/individual/add`}
              className="bg-blue-2/15 py-px px-8 rounded-lg max-w-max w-full flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
            >
              <img
                className="w-8 h-9 object-contain"
                src={addParcelImage}
                alt="add"
              />
              Add Individual Parcel
            </Link>
          ) : (
            <Link
              to={`/ParcelGroupNew/${parcelGroupId}/Parcels/standart/add`}
              className="bg-blue-2/15 py-px px-8 rounded-lg max-w-max w-full flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
            >
              <img
                className="w-8 h-9 object-contain"
                src={addParcelImage}
                alt="add"
              />
              Add Standart Parcel
            </Link>
          )} */}

          {openedGroup?.parcelGroupTypeId === 2 &&
            openedGroup?.status === "A" && (
              <Link
                to={`/ParcelGroupNew/${parcelGroupId}/Parcels/individual/add`}
                className="bg-blue-2/15 py-px px-8 rounded-lg max-w-max w-full flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
              >
                <img
                  className="w-8 h-9 object-contain"
                  src={addParcelImage}
                  alt="add"
                />
                Add Individual Parcel
              </Link>
            )}

          {openedGroup?.parcelGroupTypeId === 1 &&
            openedGroup?.status === "A" && (
              <Link
                to={`/ParcelGroupNew/${parcelGroupId}/Parcels/standart/add`}
                className="bg-blue-2/15 py-px px-8 rounded-lg max-w-max w-full flex justify-center items-center gap-1.5 whitespace-nowrap text-xs font-medium text-blue-2 cursor-pointer transition-all duration-300 hover:bg-blue-2/50"
              >
                <img
                  className="w-8 h-9 object-contain"
                  src={addParcelImage}
                  alt="add"
                />
                Add Standart Parcel
              </Link>
            )}
        </div>
      </div>
      {showResponseMessage && (
        <Snackbar
          open={showResponseMessage}
          autoHideDuration={severity === "error" ? null : 5000}
          onClose={handleSnackbarClose}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={severity}
            variant="filled"
            sx={{ width: "100%" }}
          >
            {message}
          </Alert>
        </Snackbar>
      )}
      {deleteConfirmation && (
        <DeleteConfirmation
          handleCancel={handleDeleteConfirmation}
          handleDelete={handleDeleteParcels}
        />
      )}
      <Modal showmodal={showPicturesList} setShowModal={setShowPicturesList}>
        <PicturesList
          parcelId={parcelId}
          handleShowModal={() => setShowPicturesList(false)}
          selectedImage={selectedImage}
          setSelectedImage={setSelectedImage}
        />
      </Modal>
      <Modal
        showmodal={showGroupChangeModal}
        setShowModal={setShowGroupChangeModal}
      >
        <ChangeGroupForParcel
          handleShowModal={() => setShowGroupChangeModal(false)}
          parcelIds={parcelIds}
          setParcelIds={setParcelIds}
          setSelectedRows={setSelectedRows}
          setShowResponseMessage={setShowResponseMessage}
          setSeverity={setSeverity}
          setMessage={setMessage}
          setShowGroupChangeModal={setShowGroupChangeModal}
        />
      </Modal>
      {selectedImage && (
        <div className="fixed inset-0 z-50">
          <div
            className="w-full h-full bg-black/20"
            onClick={handleCloseImage}
          ></div>
          <div className="absolute max-w-6xxl w-full p-4 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex justify-center items-center">
            <TransformWrapper disablePadding="true">
              <TransformComponent>
                <img className="w-full" src={selectedImage} alt="Selected" />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </div>
      )}
      {parcelIds.length > 0 && (
        <div className="bg-gray-100 border-b border-gray-300 p-4 w-full flex items-center justify-start gap-2">
          {openedGroup.status === "A" && (
            <Tooltip title={t("text.delete")}>
              <button
                className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
                onClick={handleDeleteConfirmation}
              >
                <DeleteSvg />
              </button>
            </Tooltip>
          )}
          <Tooltip title={t("text.change_parcel_group")}>
            <button
              className="p-2 bg-white border border-gray-300 rounded-md hover:bg-gray-200"
              onClick={handleShowRelocation}
            >
              <Rechange />
            </button>
          </Tooltip>
        </div>
      )}
      <ParcelsTable
        handleShowPicturesList={handleShowPicturesList}
        typeId={openedGroup?.parcelGroupTypeId}
        parcelIds={parcelIds}
        setParcelIds={setParcelIds}
        selectedRows={selectedRows}
        setSelectedRows={setSelectedRows}
      />
    </ParcelGroupsLayout>
  );
};

export default ParcelsNew;
