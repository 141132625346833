import { useSelector } from "react-redux";
import ModalsHeadline from "../components/ModalsHeadline";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PicturesList = ({ parcelId, handleShowModal, setSelectedImage }) => {
  const [openedParcel, setOpenedParcel] = useState(null);

  const { parcels } = useSelector((state) => state?.groups?.parcels);
  const { t } = useTranslation();

  useEffect(() => {
    const openedParcel = parcels?.find((item) => item.id === parcelId);
    setOpenedParcel(openedParcel);
  }, [parcelId, parcels]);

  const handleOpenImage = (fileUrl) => {
    setSelectedImage(fileUrl);
  };

  return (
    <div className="flex flex-col justify-between rounded-lg h-full">
      <div>
        <ModalsHeadline text="Uploaded Pictures" onClick={handleShowModal} />
        <div className="p-6 w-full grid grid-cols-2 gap-4">
          {openedParcel && openedParcel?.parcelFiles.length > 0 ? (
            openedParcel?.parcelFiles.map((file, index) => (
              <div
                key={index}
                className="cursor-pointer"
                onClick={() => handleOpenImage(file.fileUrl)}
              >
                <img
                  src={file.fileUrl}
                  alt={file.fileName}
                  className="object-cover w-full h-40 rounded-lg shadow"
                />
              </div>
            ))
          ) : (
            <div className="w-full pt-10 flex justify-center items-center text-sm text-blue-1">
              {t("text.not_found")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PicturesList;
