import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCities } from "../../features/groups/cities/CitiesSlice";
import ModalsHeadline from "../../components/modals/components/ModalsHeadline";
import SelectToSetLarge from "../common/shortList/SelectToSetLarge";
import GoogleMap from "./GoogleMap";
import ButtonDefault from "../buttons/ButtonDefault";
import { useTranslation } from "react-i18next";

const AddressSearchMap = ({
  setShowModal,
  formData,
  setFormData,
  handleInputChange,
}) => {
  const { cities } = useSelector((state) => state.cities);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCities());
  }, [dispatch]);

  const handleOnChange = (e) => {
    const { name, value } = e.target;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <div className="flex flex-col justify-between rounded-lg h-full">
      <div>
        <ModalsHeadline
          text="Select an address"
          onClick={() => setShowModal(false)}
        />
        <div className="p-6 w-full flex flex-col gap-4">
          <div className="relative w-full">
            <SelectToSetLarge
              text="City"
              options={cities.map((cat) => ({
                value: cat.id,
                label: cat.cityDesc,
              }))}
              name="cityId"
              value={formData.cityId}
              onChange={handleOnChange}
            />
          </div>
          {formData.cityId && (
            <GoogleMap
              setFormData={setFormData}
              formData={formData}
              handleInputChange={handleInputChange}
            />
          )}
        </div>
      </div>
      <div className="p-6">
        <ButtonDefault onClick={() => setShowModal(false)} type="button">
          {t("button.save")}
        </ButtonDefault>
      </div>
    </div>
  );
};

export default AddressSearchMap;
