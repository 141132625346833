import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoWarning } from "react-icons/io5";
import {
  generateTrackingCode,
  reset,
} from "../../../features/groups/trackingCodeSlice";
import { getDepartments } from "../../../features/groups/departments/DepartmenstSlice";
import {
  getCategoriesWithMaster,
  getNewParcelInfo,
  getParcelServices,
} from "../../../features/groups/GroupsSlice";
import {
  CloseModalSvg,
  LengthSvg,
  LocationSvg,
  QuantitySvg,
  WeightSvg,
  WidthSvg,
} from "../../icons";
import ButtonPrimary from "../../buttons/ButtonPrimary";
import InputSmall from "../../common/InputSmall";
import Checkbox from "../../common/Checkbox";
import SelectSmall from "../../common/SelectSmall";
import Input from "../../common/Input";
import upload from "../../../assets/upload-picture.png";
import clear from "../../../assets/clear.png";
import Modal from "../../common/Modal";
import SelectToSetLarge from "../../common/shortList/SelectToSetLarge";
import {
  getPotentialRealUsers,
  reset as resetUsers,
} from "../../../features/groups/PotentialRealSlice";
import {
  calculatePrice,
  reset as resetCalcs,
} from "../../../features/groups/CalculationsSlice";
import AddressSearchSmall from "../../googleApiSearch/AddressSearchSmall";
import AddressSearchMap from "../../googleApiSearch/AddressSearchMap";

const IndividualParcelForm = ({
  handleSubmit,
  setFormData,
  formData,
  setShouldPrint,
  shouldPrint,
  setSelectedMainCategory,
  setSelectedSubCategory,
  selectedMainCategory,
  selectedSubCategory,
  setShowUploadModal,
  subCategories,
  setSubCategories,
}) => {
  const [isDivVisible, setIsDivVisible] = useState(true);
  const [showOptions, setShowOptions] = useState({
    sender: {
      firstName: false,
      lastName: false,
      pinOrInn: false,
    },
    receiver: {
      firstName: false,
      lastName: false,
      pinOrInn: false,
    },
  });
  const [showAddressModal, setShowAddressModal] = useState(false);
  const parcelCodeInputRef = useRef(null);
  const weightInputRef = useRef(null);
  const priceInputRef = useRef(null);
  const debounceTimeout = useRef(null);
  const debounceTimeout2 = useRef(null);
  const dropdownRef = useRef(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { newParcelInfo, categoriesWithMaster, parcelServices } = useSelector(
    (state) => state.groups
  );
  const { potentialRealUsers } = useSelector(
    (state) => state.potentialRealUsers
  );
  const { trackingCode } = useSelector((state) => state.trackingCode);
  const { departments } = useSelector((state) => state.parcelDepartments);
  const { price } = useSelector((state) => state.calculatedPrice);

  useEffect(() => {
    const formattedParcelDetails = formData.parcelDetails
      .map((detail) => ({
        quantity: parseFloat(detail.quantity) || 0,
        weight: parseFloat(detail.weight) || 0,
        length: parseFloat(detail.length) || 0,
        width: parseFloat(detail.width) || 0,
        height: parseFloat(detail.height) || 0,
      }))
      .filter(
        (detail) =>
          detail.quantity !== 0 ||
          detail.weight !== 0 ||
          detail.length !== 0 ||
          detail.width !== 0 ||
          detail.height !== 0
      );

    if (
      formData.parcelGroupId &&
      formData.parcelDetails &&
      (formData.receiverPotentialUserId ||
        formData.receiverUserId ||
        formData.receiver) &&
      (formData.departmentId || formData.adddress)
    ) {
      if (debounceTimeout2.current) clearTimeout(debounceTimeout2.current);
      debounceTimeout2.current = setTimeout(() => {
        dispatch(
          calculatePrice({
            parcelGroupId: formData.parcelGroupId || "",
            parcelDetails: JSON.stringify(formattedParcelDetails),
            adddress: formData.adddress || "",
            departmentId: formData.departmentId || "",
            receiver: JSON.stringify(formData.receiver) || "",
            receiverUserId: formData.receiverUserId || "",
            senderUserId: formData.senderUserId || "",
            senderPotentialUserId: formData.senderPotentialUserId || "",
            receiverPotentialUserId: formData.receiverPotentialUserId || "",
          })
        );
      }, 500);
    } else {
      dispatch(resetCalcs());
    }
  }, [
    formData.parcelGroupId,
    formData.parcelDetails,
    formData.adddress,
    formData.departmentId,
    formData.receiver,
    formData.receiverUserId,
    formData.senderUserId,
    formData.senderPotentialUserId,
    formData.receiverPotentialUserId,
    dispatch,
  ]);

  useEffect(() => {
    dispatch(getCategoriesWithMaster());
    dispatch(getParcelServices());
    dispatch(getDepartments());
  }, [dispatch]);

  useEffect(() => {
    parcelCodeInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (newParcelInfo && newParcelInfo.length > 0) {
      setFormData((prev) => ({
        ...prev,
        roomNumber: newParcelInfo[0]?.roomNumber,
      }));
    }
  }, [newParcelInfo, setFormData]);

  useEffect(() => {
    if (trackingCode?.message) {
      setFormData((prev) => ({
        ...prev,
        tdsCode: trackingCode.message,
      }));
    }
  }, [trackingCode, setFormData]);

  useEffect(() => {
    if (categoriesWithMaster.length > 0) {
      const allSubcategories = categoriesWithMaster.flatMap(
        (category) => category.children || []
      );
      setSubCategories(allSubcategories);
    }
  }, [categoriesWithMaster, setSubCategories]);

  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      warehouseCategoryId: selectedSubCategory,
    }));
  }, [selectedSubCategory, setFormData]);

  useEffect(() => {
    if (formData.departmentId) {
      setFormData((prev) => ({
        ...prev,
        cityId: "",
        address: "",
        addressComment: "",
      }));
    }
  }, [formData.departmentId, setFormData]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions((prev) => ({
          ...prev,
          sender: {
            name: false,
            lastName: false,
            pinOrInn: false,
          },
          receiver: {
            name: false,
            lastName: false,
            pinOrInn: false,
          },
        }));
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const calculateTotals = () => {
    const totalQuantity = formData.parcelDetails.reduce(
      (total, detail) => total + (parseFloat(detail.quantity) || 0),
      0
    );

    const totalWeight = formData.parcelDetails.reduce((total, detail) => {
      const weight = parseFloat(detail.weight) || 0;
      const length = parseFloat(detail.length) || 0;
      const width = parseFloat(detail.width) || 0;
      const height = parseFloat(detail.height) || 0;

      const volumetricWeight = (length * width * height) / 6000;

      const effectiveWeight = Math.max(weight, volumetricWeight);

      return total + effectiveWeight;
    }, 0);

    return {
      totalQuantity: parseFloat(totalQuantity.toFixed(2)),
      totalWeight: parseFloat(totalWeight.toFixed(2)),
    };
  };

  const { totalQuantity, totalWeight } = calculateTotals();

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const [fieldGroup, fieldName] = name.split(".");
    const numericFields = ["quantity", "weight", "length", "width", "height"];
    const isNumericField = numericFields.includes(name);
    const shouldShowOptions = value.length > 3;

    if (isNumericField && !/^\d*\.?\d*$/.test(value)) {
      return;
    }

    if (
      name === "quantity" ||
      name === "weight" ||
      name === "length" ||
      name === "width" ||
      name === "height"
    ) {
      const updatedParcelDetails = [...formData.parcelDetails];
      updatedParcelDetails[index] = {
        ...updatedParcelDetails[index],
        [name]: value,
      };

      const length = parseFloat(updatedParcelDetails[index].length || 0);
      const width = parseFloat(updatedParcelDetails[index].width || 0);
      const height = parseFloat(updatedParcelDetails[index].height || 0);
      const VOLUMETRIC_FACTOR = 6000;

      if (length && width && height) {
        updatedParcelDetails[index].volumetricWeight =
          (length * width * height) / VOLUMETRIC_FACTOR;
      } else {
        updatedParcelDetails[index].volumetricWeight = undefined;
      }

      const isRowEmpty = Object.values(updatedParcelDetails[index]).every(
        (field) => field === "" || field === undefined
      );

      if (isRowEmpty && updatedParcelDetails.length > 1) {
        updatedParcelDetails.splice(index, 1);
      }

      const lastBlock = updatedParcelDetails[updatedParcelDetails.length - 1];
      if (
        index === updatedParcelDetails.length - 1 &&
        (lastBlock.quantity ||
          lastBlock.length ||
          lastBlock.width ||
          lastBlock.height)
      ) {
        updatedParcelDetails.push({
          quantity: "",
          weight: "",
          length: "",
          width: "",
          height: "",
        });
      }

      setFormData({
        ...formData,
        parcelDetails: updatedParcelDetails,
      });

      return;
    }

    if (fieldGroup === "sender" || fieldGroup === "receiver") {
      const transliterateToEnglish = (text) => {
        const transliterationMap = {
          ა: "a",
          ბ: "b",
          გ: "g",
          დ: "d",
          ე: "e",
          ვ: "v",
          ზ: "z",
          თ: "t",
          ი: "i",
          კ: "k",
          ლ: "l",
          მ: "m",
          ნ: "n",
          ო: "o",
          პ: "p",
          ჟ: "j",
          რ: "r",
          ს: "s",
          ტ: "t",
          უ: "u",
          ფ: "f",
          ქ: "q",
          ღ: "r",
          შ: "s",
          ჩ: "c",
          ც: "c",
          ძ: "z",
          წ: "w",
          ჭ: "w",
          ხ: "x",
          ჯ: "j",
          ჰ: "h",
        };

        return text
          .split("")
          .map((char) => {
            const isUpperCase = char === char.toUpperCase();
            const lowerChar = char.toLowerCase();
            const transliterated = transliterationMap[lowerChar] || char;
            return isUpperCase ? transliterated.toUpperCase() : transliterated;
          })
          .join("");
      };

      const processedValue = transliterateToEnglish(value);

      setFormData((prev) => ({
        ...prev,
        [fieldGroup]: {
          ...prev[fieldGroup],
          [fieldName]: processedValue,
        },
      }));

      setShowOptions((prev) => ({
        ...prev,
        [fieldGroup]: {
          ...prev[fieldGroup],
          [fieldName]: shouldShowOptions,
        },
      }));

      if (shouldShowOptions) {
        if (debounceTimeout.current) clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
          const transliteratedValue = transliterateToEnglish(value);
          dispatch(getPotentialRealUsers(transliteratedValue));
        }, 500);
      }
    }

    if (
      fieldGroup !== "sender" &&
      fieldGroup !== "receiver" &&
      name !== "quantity" &&
      name !== "weight" &&
      name !== "length" &&
      name !== "width" &&
      name !== "height"
    ) {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleKeyDown = async (e) => {
    const { name } = e.target;

    if (e.key === "Enter") {
      e.preventDefault();

      if (name === "tdsCode" && formData.tdsCode !== "") {
        const response = await dispatch(getNewParcelInfo(formData.tdsCode));
        if (response.payload && response.payload.length > 0) {
          priceInputRef.current.focus();
        }
      } else {
        const formElements = Array.from(e.target.form.elements);
        const currentElementIndex = formElements.indexOf(e.target);
        const nextElement = formElements[currentElementIndex + 1];

        if (nextElement) {
          nextElement.focus();
        }
      }
    }
  };

  const handleGenerateTrackingCode = async () => {
    await dispatch(generateTrackingCode());
    dispatch(reset());
  };

  const handleMainCategoryChange = (e) => {
    const selectedCategoryId = e.target.value;

    setSelectedMainCategory(selectedCategoryId);
    setSelectedSubCategory("");

    const parentCategory = categoriesWithMaster.find(
      (category) => category.id === parseInt(selectedCategoryId)
    );
    setSubCategories(parentCategory?.children || []);
  };

  const handleSubCategoryChange = (e) => {
    const selectedSubCategoryId = e.target.value;

    setSelectedSubCategory(selectedSubCategoryId);
    const parentCategory = categoriesWithMaster.find((category) =>
      category.children.some(
        (sub) => sub.id === parseInt(selectedSubCategoryId)
      )
    );
    if (parentCategory) {
      setSelectedMainCategory(parentCategory.id);
    }
  };

  const handleClearForm = () => {
    dispatch(resetUsers());
    dispatch(resetCalcs());
    setFormData((prev) => ({
      ...prev,
      tdsCode: "",
      parcelDetails: [
        {
          quantity: "",
          weight: "",
          length: "",
          width: "",
          height: "",
        },
      ],
      warehouseComment: "",
      warehouseCategoryId: "",
      "file[]": [],
      senderPotentialUserId: "",
      receiverUserId: "",
      senderUserId: "",
      address: "",
      departmentId: "",
      price: "",
      cityId: "",
      addressComment: "",
      sender: {
        firstName: "",
        lastName: "",
        pinOrInn: "",
        address: "",
        mobile: "",
        email: "",
        additionalInformation: "",
      },
      receiver: {
        firstName: "",
        lastName: "",
        pinOrInn: "",
        address: "",
        mobile: "",
        email: "",
        additionalInformation: "",
      },
    }));
    setSelectedSubCategory("");
    setSelectedMainCategory("");
    parcelCodeInputRef.current.focus();
  };

  const handleCloseDiv = () => {
    setIsDivVisible(false);
  };

  const handleChoiseSenderUser = (id) => {
    let user = potentialRealUsers?.find((user) => user.id === id);

    if (user) {
      setFormData((prev) => ({
        ...prev,
        sender: {
          ...prev.sender,
          firstName: user.firstNameEn || "",
          lastName: user.lastNameEn || "",
          pinOrInn: user.pinOrInn || "",
        },
        senderPotentialUserId: user.potentialUserId || "",
        senderUserId: user.id || "",
      }));

      setShowOptions((prev) => ({
        ...prev,
        sender: {
          name: false,
          lastName: false,
          pinOrInn: false,
        },
      }));
    }
  };

  const handleChoiseReceiverUser = (id) => {
    let user = potentialRealUsers?.find((user) => user.id === id);

    if (user) {
      setFormData((prev) => ({
        ...prev,
        receiver: {
          ...prev.receiver,
          firstName: user.firstNameEn || "",
          lastName: user.lastNameEn || "",
          pinOrInn: user.pinOrInn || "",
        },
        receiverUserId: user.id || "",
        receiverPotentialUserId: user.potentialUserId || "",
      }));

      setShowOptions((prev) => ({
        ...prev,
        receiver: {
          name: false,
          lastName: false,
          pinOrInn: false,
        },
      }));
    }
  };

  const handleShowAddressModal = () => {
    setShowAddressModal(!showAddressModal);
  };

  return (
    <>
      <Modal showmodal={showAddressModal} setShowModal={setShowAddressModal}>
        <AddressSearchMap
          setShowModal={setShowAddressModal}
          handleInputChange={handleInputChange}
          formData={formData}
          setFormData={setFormData}
        />
      </Modal>
      <form
        onSubmit={handleSubmit}
        className="grid gap-4 xl:grid-cols-[auto_319px] px-6 py-10 transition-all duration-500"
      >
        <div className="flex flex-col items-start gap-6">
          <div className="w-full grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="gap-4 flex flex-col items-start">
              <div className="relative w-full flex">
                {formData.sender.firstName &&
                formData.sender.lastName &&
                formData.sender.pinOrInn &&
                formData.senderUserId ? (
                  <span className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                    {formData.sender.firstName}
                  </span>
                ) : (
                  <InputSmall
                    value={formData.sender.firstName}
                    name="sender.firstName"
                    onchange={handleInputChange}
                    text="სახელი"
                  />
                )}
                {showOptions.sender.firstName && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full z-20 w-full max-h-lg min-h-20 overflow-auto rounded-xl bg-white border border-gray-200"
                  >
                    {potentialRealUsers.length ? (
                      potentialRealUsers.map((user) => (
                        <div
                          key={user.id}
                          onClick={() => handleChoiseSenderUser(user.id)}
                          className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                        >
                          {user.label}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="relative w-full flex">
                {formData.sender.firstName &&
                formData.sender.lastName &&
                formData.sender.pinOrInn &&
                formData.senderUserId ? (
                  <span className="w-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                    {formData.sender.lastName}
                  </span>
                ) : (
                  <InputSmall
                    value={formData.sender.lastName}
                    name="sender.lastName"
                    onchange={handleInputChange}
                    text="გვარი"
                  />
                )}
                {showOptions.sender.lastName && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full z-20 w-full max-h-lg min-h-20 overflow-auto rounded-xl bg-white border border-gray-200"
                  >
                    {potentialRealUsers.length ? (
                      potentialRealUsers.map((user) => (
                        <div
                          key={user.id}
                          onClick={() => handleChoiseSenderUser(user.id)}
                          className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                        >
                          {user.label}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="relative w-full flex">
                <InputSmall
                  value={formData.sender.pinOrInn}
                  name="sender.pinOrInn"
                  onchange={handleInputChange}
                  text="საიდენტიფიკაციო კოდი"
                />
                {showOptions.sender.pinOrInn && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full z-20 w-full max-h-lg min-h-20 overflow-auto rounded-xl bg-white border border-gray-200"
                  >
                    {potentialRealUsers.length ? (
                      potentialRealUsers.map((user) => (
                        <div
                          key={user.id}
                          onClick={() => handleChoiseSenderUser(user.id)}
                          className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                        >
                          {user.label}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <AddressSearchSmall
                onUpdate={(address) =>
                  setFormData((prev) => ({
                    ...prev,
                    sender: { ...prev.sender, address },
                  }))
                }
                value={formData.sender.address}
                text="მისამართი"
              />
              <InputSmall
                value={formData.sender.mobile}
                name="sender.mobile"
                onchange={handleInputChange}
                text="ტელეფონის ნომერი"
              />
              <InputSmall
                value={formData.sender.email}
                name="sender.email"
                onchange={handleInputChange}
                text="ელ-ფოსტა"
              />
              <InputSmall
                value={formData.sender.additionalInformation}
                name="sender.additionalInformation"
                onchange={handleInputChange}
                text="კომენტარი"
              />
            </div>
            <div className="gap-4 flex flex-col items-start">
              <div className="relative w-full flex">
                {formData.receiver.firstName &&
                formData.receiver.lastName &&
                formData.receiver.pinOrInn &&
                formData.receiverUserId ? (
                  <span className="w-full top-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                    {formData.receiver.firstName}
                  </span>
                ) : (
                  <InputSmall
                    value={formData.receiver.firstName}
                    name="receiver.firstName"
                    onchange={handleInputChange}
                    text="სახელი"
                  />
                )}
                {showOptions.receiver.firstName && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full z-20 w-full max-h-lg min-h-20 overflow-auto rounded-xl bg-white border border-gray-200"
                  >
                    {potentialRealUsers ? (
                      potentialRealUsers.map((user) => (
                        <div
                          key={user.id}
                          onClick={() => handleChoiseReceiverUser(user.id)}
                          className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                        >
                          {user.label}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="relative w-full flex">
                {formData.receiver.firstName &&
                formData.receiver.lastName &&
                formData.receiver.pinOrInn &&
                formData.receiverUserId ? (
                  <span className="w-full top-full rounded-xl overflow-hidden bg-white border border-gray-200 py-2.5 px-3 leading-5 text-sm text-gray-1">
                    {formData.receiver.lastName}
                  </span>
                ) : (
                  <InputSmall
                    value={formData.receiver.lastName}
                    name="receiver.lastName"
                    onchange={handleInputChange}
                    text="გვარი"
                  />
                )}
                {showOptions.receiver.lastName && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full z-20 w-full max-h-lg min-h-20 overflow-auto rounded-xl bg-white border border-gray-200"
                  >
                    {potentialRealUsers ? (
                      potentialRealUsers.map((user) => (
                        <div
                          key={user.id}
                          onClick={() => handleChoiseReceiverUser(user.id)}
                          className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                        >
                          {user.label}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="relative w-full flex">
                <InputSmall
                  value={formData.receiver.pinOrInn}
                  name="receiver.pinOrInn"
                  onchange={handleInputChange}
                  text="საიდენტიფიკაციო კოდი"
                />
                {showOptions.receiver.pinOrInn && (
                  <div
                    ref={dropdownRef}
                    className="absolute top-full z-20 w-full max-h-lg min-h-20 overflow-auto rounded-xl bg-white border border-gray-200"
                  >
                    {potentialRealUsers ? (
                      potentialRealUsers.map((user) => (
                        <div
                          key={user.id}
                          onClick={() => handleChoiseReceiverUser(user.id)}
                          className="px-4 py-1.5 text-gray-1 text-sm font-normal cursor-pointer transition-all duration-300 hover:bg-gray-1/5"
                        >
                          {user.label}
                        </div>
                      ))
                    ) : (
                      <div className="px-4 py-10 w-full text-gray-1 flex justify-center items-center text-xs font-medium leading-4">
                        {t("text.not_found")}
                      </div>
                    )}
                  </div>
                )}
              </div>
              <AddressSearchSmall
                onUpdate={(address) =>
                  setFormData((prev) => ({
                    ...prev,
                    receiver: { ...prev.receiver, address },
                  }))
                }
                value={formData.receiver.address}
                text="მისამართი"
              />
              <InputSmall
                value={formData.receiver.mobile}
                name="receiver.mobile"
                onchange={handleInputChange}
                text="ტელეფონის ნომერი"
              />
              <InputSmall
                value={formData.receiver.email}
                name="receiver.email"
                onchange={handleInputChange}
                text="ელ-ფოსტა"
              />
              <InputSmall
                value={formData.receiver.additionalInformation}
                name="receiver.additionalInformation"
                onchange={handleInputChange}
                text="კომენტარი"
              />
            </div>
          </div>
          <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-2">
            <div className="relative">
              <div className="relative">
                <span
                  className="cursor-pointer absolute z-20 top-1/2 transform -translate-y-1/2 right-2 text-blue-2 bg-blue-2/30 text-xs py-1 px-2.5 rounded-lg transition-all duration-300 hover:bg-blue-2/40"
                  onClick={handleGenerateTrackingCode}
                >
                  Generate
                </span>
                <InputSmall
                  inputRef={parcelCodeInputRef}
                  onKeyDown={(e) => handleKeyDown(e, 0)}
                  value={formData.tdsCode}
                  name="tdsCode"
                  onchange={handleInputChange}
                  text="გზავნილის კოდი"
                />
              </div>
            </div>

            <InputSmall
              inputRef={priceInputRef}
              value={formData.price}
              name="price"
              onchange={handleInputChange}
              text="ღირებულება"
            />
          </div>

          {formData.parcelDetails.map((detail, index) => (
            <div
              key={index}
              className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl xs:grid-cols-2 md:grid-cols-3 lg:grid-cols-6"
            >
              <div className="relative">
                <InputSmall
                  inputRef={index === 0 ? weightInputRef : null}
                  text="წონა"
                  name="weight"
                  onchange={(e) => handleInputChange(e, index)}
                  value={detail.weight}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  isicon="isicon"
                  icon={<WeightSvg />}
                />
              </div>

              <div className="relative">
                <InputSmall
                  text="რაოდენობა"
                  name="quantity"
                  onchange={(e) => handleInputChange(e, index)}
                  value={detail.quantity}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  isicon="isicon"
                  icon={<QuantitySvg />}
                />
              </div>

              <InputSmall
                text="სიგრძე (სმ)"
                name="length"
                onchange={(e) => handleInputChange(e, index)}
                value={detail.length}
                onKeyDown={(e) => handleKeyDown(e, index)}
                isicon="isicon"
                icon={<LengthSvg />}
              />

              <InputSmall
                text="სიგანე (სმ)"
                name="width"
                onchange={(e) => handleInputChange(e, index)}
                value={detail.width}
                onKeyDown={(e) => handleKeyDown(e, index)}
                isicon="isicon"
                icon={<WidthSvg />}
              />

              <InputSmall
                text="სიმაღლე (სმ)"
                name="height"
                onchange={(e) => handleInputChange(e, index)}
                value={detail.height}
                onKeyDown={(e) => handleKeyDown(e, index)}
                isicon="isicon"
                icon={<LengthSvg />}
              />

              <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
                {detail.volumetricWeight
                  ? detail.volumetricWeight.toFixed(2)
                  : "N/A"}
              </div>
            </div>
          ))}

          <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
            <SelectSmall
              options={categoriesWithMaster.map((cat) => ({
                value: cat.id,
                label: cat.description,
              }))}
              text="Goods type"
              name="warehouseCategoryId"
              value={selectedMainCategory}
              onchange={handleMainCategoryChange}
            />
            <SelectSmall
              options={subCategories.map((sub) => ({
                value: sub.id,
                label: sub.description,
              }))}
              text="Sub type"
              name="warehouseSubCategory"
              value={selectedSubCategory}
              onchange={handleSubCategoryChange}
            />
          </div>

          <div className="w-full grid grid-cols-1 gap-4 rounded-xl lg:grid-cols-2">
            <SelectToSetLarge
              text="Department"
              options={departments.map((cat) => ({
                value: cat.id,
                label: cat.address,
              }))}
              name="departmentId"
              value={formData.departmentId}
              onChange={handleInputChange}
            />
            {/* <AddressSearch
            onUpdate={(address) =>
              setFormData((prev) => ({ ...prev, address }))
            }
            text="Address"
          /> */}
            <button
              type="button"
              className={`${
                formData.departmentId ? "opacity-45" : "opacity-1"
              } flex justify-start items-center gap-2 w-full top-full rounded-lg overflow-hidden bg-white border border-gray-200 py-4 px-3 leading-5 text-xs md:text-sm text-gray-1`}
              disabled={formData.departmentId}
              onClick={handleShowAddressModal}
            >
              <LocationSvg />
              {formData.address
                ? formData.address?.name || formData.address?.formatted_address
                : "Select an address"}
            </button>
          </div>
          <div className="w-full grid grid-cols-1 py-3 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-3">
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              ჯამური რაოდენობა: {totalQuantity}
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              ჯამური წონა: {totalWeight}
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              საკურიერო ღირებულება: {price?.courierPrice}$
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              მომხმარებლისგან მისაღები თანხა: {price?.totalPrice}$
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              სფეის კარგოზე გადასახდელი თანხა: {price?.spaceCargoAmount}$
            </div>
            <div className="text-xs text-blue-2 font-medium bg-blue-2/15 flex justify-start items-center rounded-lg py-2 px-3">
              თქვენი მოგება: {price?.companyProfit}$
            </div>
          </div>
          {newParcelInfo[0]?.parcelServices ? (
            <div className="relative w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
              {newParcelInfo[0]?.parcelServices.map((service, index) => (
                <div
                  key={index}
                  className="flex justify-center items-center gap-2"
                >
                  <Checkbox
                    checked={service.serviceStatusFlag === "Y" ? true : false}
                  />
                  <span className="text-xs text-gray-400">
                    {service.serviceDesc}
                  </span>
                </div>
              ))}
              {newParcelInfo[0]?.parcelServices.some(
                (service) => service.serviceStatusFlag === "Y"
              ) &&
                isDivVisible && (
                  <div className="absolute right-2 bottom-2 max-w-64 w-full bg-gray-200 shadow-md py-5 px-6 rounded-xl text-sm text-blue-1 border-gray-200 flex flex-col gap-2 items-center">
                    <div
                      className="absolute top-2 right-2 cursor-pointer w-5 h-5 flex justify-center items-center opacity-50 hover:opacity-100 transition-all duration-300"
                      onClick={handleCloseDiv}
                    >
                      <CloseModalSvg />
                    </div>
                    <IoWarning className="text-yellow-400 text-4xl" />
                    <p className="text-xs text-gray-6 text-center">
                      მომხმარებელი ითხოვს{" "}
                      {newParcelInfo[0]?.parcelServices
                        .filter((service) => service.serviceStatusFlag === "Y")
                        .map((service) => service.serviceDesc)
                        .join(", ")}
                    </p>
                  </div>
                )}
            </div>
          ) : (
            <div className="relative w-full grid grid-cols-1 py-5 px-6 gap-4 bg-gray-100 border border-gray-200 rounded-xl lg:grid-cols-5">
              {parcelServices?.map((service) => (
                <div
                  key={service.id}
                  className="flex justify-center items-center gap-2"
                >
                  <Checkbox checked={false} />
                  <span className="text-xs text-gray-400">
                    {service.serviceDesc}
                  </span>
                </div>
              ))}
            </div>
          )}

          <div className="w-full">
            <Input
              text="კომენტარი"
              name="warehouseComment"
              onchange={handleInputChange}
              value={formData.warehouseComment}
            />
          </div>
        </div>
        <div className="flex flex-col justify-between gap-4 xl:px-9">
          <div className="flex flex-col items-center w-full gap-5">
            <div
              onClick={handleClearForm}
              className="w-full p-1.5 flex justify-center items-center gap-4 rounded-xl border border-red-300 cursor-pointer transition-all duration-300 hover:border-red-500"
            >
              <img src={clear} alt="clear" />
              <span className=" text-base font-medium text-blue-1">Clear</span>
            </div>
            <div
              onClick={() => setShowUploadModal(true)}
              className="w-full p-1.5 flex justify-center items-center gap-4 rounded-xl border border-dashed border-blue-300 cursor-pointer transition-all duration-300 hover:border-solid"
            >
              <img src={upload} alt="" />
              <span className=" text-base font-medium text-blue-1">
                Upload Image
              </span>
            </div>
            <div className="flex justify-start items-center gap-2 w-full text-base text-purple-3">
              <Checkbox
                checked={shouldPrint}
                onChange={() => setShouldPrint(!shouldPrint)}
              />
              Print
            </div>
          </div>
          <ButtonPrimary type="submit">შენახვა</ButtonPrimary>
        </div>
      </form>
    </>
  );
};

export default IndividualParcelForm;
