import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ArrowDownSvg } from "../icons";
import { Tooltip } from "@mui/material";

const DropLinks = ({ link, icons, handleNavClick, activeLink }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="w-full">
      <button
        className="w-full flex items-center justify-between px-4 py-3 cursor-pointer "
        onClick={toggleDropdown}
      >
        <span
          className={`flex items-center gap-4 text-gray-600 text-sm font-normal ${
            isOpen ? "text-blue-1" : ""
          }`}
        >
          {icons[link.iconUrl]}
          {t(link.pageDictionaryKey)}
        </span>
        <div className={`transition-transform ${isOpen ? "rotate-180" : ""}`}>
          <ArrowDownSvg />
        </div>
      </button>
      {isOpen && (
        <ul className="px-4 w-full">
          {link.children.map((subLink, index) => (
            <>
              {subLink?.visible === "Y" && (
                <li
                  key={index}
                  className={`flex items-center gap-5 px-2 text-gray-600 text-sm font-normal w-full ${
                    activeLink === subLink.pageUrl ? "font-bold" : ""
                  }`}
                >
                  <Tooltip title={t(subLink.pageDictionaryKey)}>
                    <Link
                      to={subLink.pageUrl}
                      onClick={() => handleNavClick(subLink)}
                      className="w-full flex items-center gap-5 py-2 text-gray-600 text-sm font-normal transition-colors duration-300 hover:bg-gray-100"
                    >
                      <span className="block h-1.5 w-1.5 rounded-full bg-gray-400"></span>
                      <span className="truncate">
                        {t(subLink.pageDictionaryKey)}
                      </span>
                    </Link>
                  </Tooltip>
                </li>
              )}
            </>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DropLinks;
